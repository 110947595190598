  import React, { Fragment } from "react";
  import Helmet from 'react-helmet';
  import had1 from "../assets/careerban.jpg";
  import LatestRoles from "./LatestRoles";
  import LatestRoles2 from "./LatestRoles2";
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshakeAngle ,faSearchLocation,faPersonArrowDownToLine,faPeopleGroup} from '@fortawesome/free-solid-svg-icons';

  
  const SsaCareers = () => {
    return (
      <Fragment>
        <Helmet>
          <title>
          OPAS Careers
          </title>
          <meta
            name="description"
            content="OPAS Careers"
          />
        </Helmet>
        <section className="inner_ban section_bottom_margin fadeInUp">
          {/* <Card extraClasses="px-200 py-100px powering_teams"> */}
            <img src={had1} style={{width:"100%",height: "350px"}} alt="CareersImg"></img>
          {/* </Card> */}
          <div className="banner_inner" > 
          <h1 className="whyhading elementor-animation-buzz">OPAS Careers</h1>

          </div>


        </section>
        <section className="container-fluid formcolor fadeInUp">
          <div className="container " style={{ paddingBottom: "120px" }} alt="CareersImg">
          <h2 className=" text-white mb-3 elementor-animation-buzz" style={{font:"400 34px/40px 'Poppins'",paddingTop:"50px"}}>
          Work for OPAS Group
            </h2>
            <div>
            {/* <button className="btnMessage text-end" >ENQUIRE ABOUT WORKING WITH SSA</button> */}
            </div>
            
            <div className="row">
        
              <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faHandshakeAngle} className="innovationIcon" />
              {/* <img src={Collaborative} style={{margin:"25px 0px",height:"100px"}} alt="CareersImg"></img> */}
                <h2 className="mb-3 text-white text-sm-center styh3">Opportunities</h2>
                <p className="para_font_600_20px text-sm-center styp">SSA is a strong business – we have no debt – we only trade out of a profit position.
                </p>
              </div> 
              <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              
              <FontAwesomeIcon icon={faSearchLocation} className="innovationIcon" />
              {/* <img src={Global} style={{margin:"25px 0px",height:"100px"}} alt="CareersImg"></img> */}
                <h2 className="mb-3 text-white text-sm-center styh3">Positions</h2>
                <p className="para_font_600_20px text-sm-center styp">Opas offers positions that align with your skills and aspirations, fostering growth and success.</p>
              </div>
              <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faPersonArrowDownToLine} className="innovationIcon" />
              {/* <img src={Preferred} style={{margin:"25px 0px",height:"100px"}} alt="CareersImg"></img> */}
              <h2 className="mb-3 text-white text-sm-center styh3">Roles</h2>
                <p className="para_font_600_20px text-sm-center styp">Discover meaningful roles at Opas, where each position contributes to our collective success story.</p>
              </div>
              <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faPeopleGroup} className="innovationIcon" />
              {/* <img src={Varied } style={{margin:"25px 0px",height:"100px"}} alt="CareersImg"></img> */}
                <h2 className="mb-3 text-white text-sm-center styh3">Employment</h2>
                <p className="para_font_600_20px text-sm-center styp">Opas provides not just jobs but fulfilling employment, where your career journey is our priority.</p>
              </div>
            </div>
          </div>
        </section>
 
        <LatestRoles />
        <LatestRoles2 />
        
      
      </Fragment>
    );
  };

  export default SsaCareers;

import React from "react";
import { Link } from "react-router-dom";
const Page404 = () => {
  return (
    <section className="section_bottom_margin bg-primary">
      <div className="text_div_404">
        <h1 className="text_404">404</h1>
        <h2 className="text-center w-50 m-auto text-white">
          It seems like you have landed on a wrong page Lets get you back on
          track
        </h2>
        <ul className="ul_404">
          <li>
            <Link to={'/'}>Home page</Link>
          </li>
          <li>
          <Link to={'/about-us'}>About us</Link>
          </li>
          <li>
          <Link to={'/blogs'}>Blogs</Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Page404;

import React, { Fragment, useState } from "react";
import Helmet from 'react-helmet'
// import * as XLSX from 'xlsx';
import had1 from "../assets/contactban.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';
import Map from "./Map";
import { Link } from "react-router-dom";

const ContactUs = () => {
  // const [contactSales, SetContactSales] = useState(1);
  // const [contactPartnerShips, SetcontactPartnerShips] = useState(0);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: '',
    Enquiry : "",
    region : ""
  });

  // const captchaRef = useRef(null);

  // const contactFormHandler = () => { };

  // const toggleSalesFormHandler = () => {
  //   SetContactSales(1);
  //   document.getElementById("contact_sales").classList.add("bg-grey");
  //   document.getElementById("contact_partnerships").classList.remove("bg-grey");
  //   SetcontactPartnerShips(0);
  //   captchaRef.current.reset();
  // };

  // const togglePartnershipFormHandler = () => {
  //   SetContactSales(0);
  //   document.getElementById("contact_sales").classList.remove("bg-grey");
  //   document.getElementById("contact_partnerships").classList.add("bg-grey");
  //   SetcontactPartnerShips(1);
  //   captchaRef.current.reset();
  // };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const contactData = () =>{
  //   console.log(formData);
  // }

  // const handleFormSubmit = async (event) => {
  //   event.preventDefault();

  //   // Check the ReCAPTCHA response
  //   // const captchaValue = captchaRef.current.getValue();
  //   // if (!captchaValue) {
  //   //   alert('Please complete the ReCAPTCHA verification.');
  //   //   return;
  //   // }

  //   // Convert form data to JSON
  //   const jsonData = formData;

  //   // Create a new workbook
  //   const wb = XLSX.utils.book_new();

  //   // // Add a worksheet to the workbook
  //   const ws = XLSX.utils.json_to_sheet([jsonData]);

  //   // // Add the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   // // Save the workbook as an Excel file
  //   XLSX.writeFile(wb, 'salesFormData.xlsx');
  //   window.location.reload(false);
  // };
  return (
    <Fragment>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Contact Us"
        />
      </Helmet>
      <section className="inner_ban section_bottom_margin fadeInUp">
        {/* <Card extraClasses="px-200 py-100px powering_teams"> */}
        <img src={had1} style={{ width: "100%", height: "350px" }} alt="contactusImg"></img>
        {/* </Card> */}
        <div className="banner_inner" >
          <h1 className="whyhading elementor-animation-buzz">Contact Us</h1>

        </div>


      </section>
      <Map />
      <section className="containerFluid formcolor fadeInUp">
        <div className="container" style={{ paddingBottom: "120px" }}>
          <h2 className="formH2">Make an Enquiry</h2>
          <div className="row">
            <div className="col-md-6 col-lg-6 col-sm-12">
              <label htmlFor="exampleInputEmail1" className="form-label labelemail">First Name *</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name *"
                value={formData.firstName}
                onChange={handleInputChange}
                className="formInput"
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12">
              <label htmlFor="exampleInputEmail1" className="form-label labelemail">Last Name *</label>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name *"
                value={formData.lastName}
                onChange={handleInputChange}
                className="formInput"
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 divSty">
              <label htmlFor="exampleInputEmail1" className="form-label labelemail">Phone *</label>
              <input
                type="number"
                name="phone"
                placeholder="Phone *"
                value={formData.phone}
                onChange={handleInputChange}
                className="formInput"
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 divSty">
              <label htmlFor="exampleInputEmail1" className="form-label labelemail">Email *</label>
              <input
                type="email"
                name="email"
                placeholder="Email *"
                value={formData.email}
                onChange={handleInputChange}
                className="formInput"
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 divSty">
              <label htmlFor="exampleInputEmail1" className="form-label labelemail">Region *</label>
              <select  className="form-select formInput" name="region" aria-label="Default select example" onChange={handleInputChange}>
                <option value="1">Please select a region...</option>
                <option value="United Kingdom">UAE</option>
                <option value="Ireland">Bahrain</option>
                <option value="Australia">Kuwait</option>
                <option value="United Arab Emirates">Oman</option>
                <option value="United States">Qatar</option>
                <option value="Canada">Saudi Arabia</option>
              </select>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 divSty">
              <label htmlFor="exampleInputEmail1" className="form-label labelemail" >Enquiry: *</label>

              <textarea className="formInput textformaraea" data-val="true" data-val-required="Please enter your Enquiry" id="ContactForm_Enquiry" maxLength ="1000" name="Enquiry"  value={formData.Enquiry} onChange={handleInputChange} placeholder="Enquiry*"></textarea>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 divSty">
              <p className="formp">We respect your privacy. By clicking "Send Message" you agree to our <Link to="/privacypolicy" style={{textDecoration:"none"}}><span className="spanPrivacy">Privacy Policy</span></Link></p>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 divSty" style={{ textAlign: "end" }}>
              <button className="btnMessage" 
              // onClick={handleFormSubmit}
              >Send Message</button>
            </div>
          </div>
        </div>
      </section>

    </Fragment>
  );
};

export default ContactUs;

import React, { Fragment } from "react";
import Helmet from 'react-helmet';
const CancellationPolicy = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Cancellation Policy
                </title>
                <meta
                    name="description"
                    content="Cancellation Policy for OPAS BIZZ GENERAL TRADING L.L.C-FZ"
                />
            </Helmet>
            <section className="bg-white pricing_banner_section policy fadeInUp">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1 policysH4">

                        Cancellation Policy
                    </h1>

                </div>
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px fadeInUp" style={{ padding: "100px" }}>
                <div className="section_bottom_margin ml-30 mr-30 max-w-1600px">

                    <h4 className="policysH4 mb-3">Introduction</h4>
                    <p className="directorshedaerp mb-3">This Cancellation Policy outlines the terms and conditions regarding the cancellation of services provided by OPAS BIZZ GENERAL TRADING L.L.C-FZ.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Conditions</h4>
                    <p className="directorshedaerp mb-3">Cancellations are subject to the conditions outlined in this policy and the specific terms agreed upon during service engagement.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Timeframe</h4>
                    <p className="directorshedaerp mb-3">Clients must adhere to the agreed-upon cancellation timeframe as stipulated in the service agreement.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Fees</h4>
                    <p className="directorshedaerp mb-3">Cancellation fees may apply and will be determined based on the type of service and the timing of the cancellation.</p>
                    <h4 className="policysH4 mb-3">Cancellation Requests</h4>
                    <p className="directorshedaerp mb-3">All cancellation requests must be submitted in writing, clearly stating the reasons for cancellation and providing relevant details.
                    </p>
                    <h4 className="policysH4 mb-3">Refund and Cancellation Distinction</h4>
                    <p className="directorshedaerp mb-3">Clients should note that the cancellation of services does not entitle them to a refund, as per the no-refund policy.
                    </p>
                    <h4 className="policysH4 mb-3">Late Cancellation</h4>
                    <p className="directorshedaerp mb-3">Cancellations made beyond the agreed timeframe may result in additional fees, as outlined in the service agreement.
                    </p>
                    <h4 className="policysH4 mb-3">Force Majeure Cancellation</h4>
                    <p className="directorshedaerp mb-3">In cases of force majeure events beyond the control of either party, the cancellation policy may be adjusted accordingly.
                    </p>
                    <h4 className="policysH4 mb-3">Client Responsibility</h4>
                    <p className="directorshedaerp mb-3">Clients are responsible for confirming the cancellation policy before engaging in services and adhering to its conditions.
                    </p>
                    <h4 className="policysH4 mb-3">Partial Service Cancellation</h4>
                    <p className="directorshedaerp mb-3">Cancellation of only part of the services may still incur cancellation fees for the affected portion.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Notification</h4>
                    <p className="directorshedaerp mb-3">Clients must notify the Company promptly in writing of their intention to cancel services.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Confirmation</h4>
                    <p className="directorshedaerp mb-3">Cancellation is considered confirmed only after receiving written acknowledgment from the Company.
                    </p>
                    <h4 className="policysH4 mb-3">Termination by Client</h4>
                    <p className="directorshedaerp mb-3">Clients terminating services prematurely are subject to the cancellation terms outlined in the service agreement.
                    </p>
                    <h4 className="policysH4 mb-3">Termination by Company</h4>
                    <p className="directorshedaerp mb-3">The Company reserves the right to terminate services in case of client non-compliance with terms, with applicable cancellation fees.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Due to Non-Payment</h4>
                    <p className="directorshedaerp mb-3">Services may be canceled due to non-payment, with the client remaining liable for any outstanding fees.
                    </p>
                    <h4 className="policysH4 mb-3">Service Modification</h4>
                    <p className="directorshedaerp mb-3">Clients seeking to modify services must follow the cancellation and re-engagement process, subject to applicable fees.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Review</h4>
                    <p className="directorshedaerp mb-3">Cancellation requests will be reviewed by the Company, and decisions will be communicated to the client.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Impact on Agreements</h4>
                    <p className="directorshedaerp mb-3">Cancellation of services does not relieve clients of their obligations under existing agreements, including payment obligations.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation During Force Majeure</h4>
                    <p className="directorshedaerp mb-3">In cases where force majeure events impact the ability to provide services, cancellation fees may be waived or adjusted.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation During Trading Transactions</h4>
                    <p className="directorshedaerp mb-3">For trading activities, cancellation requests are subject to the specific terms and conditions of the relevant suppliers or manufacturers.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Confirmation Timeframe</h4>
                    <p className="directorshedaerp mb-3">Clients will receive written confirmation of the cancellation within a specified timeframe.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Record</h4>
                    <p className="directorshedaerp mb-3">The Company maintains a record of all cancellations for reference and auditing purposes.
                    </p>
                    <h4 className="policysH4 mb-3">Service Suspension Instead of Cancellation</h4>
                    <p className="directorshedaerp mb-3">The Company may, at its discretion, offer clients the option to suspend services instead of canceling, with conditions outlined in the service agreement.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellations for Promotional Services</h4>
                    <p className="directorshedaerp mb-3">Promotional or discounted services may have specific cancellation terms that clients should be aware of before engagement.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation for Non-Performance</h4>
                    <p className="directorshedaerp mb-3">If the Company fails to meet its service obligations, clients may be entitled to cancel services, subject to applicable terms.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation for Client Non-Compliance</h4>
                    <p className="directorshedaerp mb-3">The Company may cancel services if clients fail to comply with agreed-upon terms, with applicable fees and penalties.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Due to Business Closure</h4>
                    <p className="directorshedaerp mb-3">Clients must notify the Company promptly if they intend to close their business, and cancellation terms will be applied accordingly.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation of Job Placement Services</h4>
                    <p className="directorshedaerp mb-3">Cancellation of job placement services is subject to the specific terms outlined in the employment contract and the service agreement.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation for Non-Communication</h4>
                    <p className="directorshedaerp mb-3">If clients fail to communicate or respond within a specified timeframe, the Company may consider the services canceled, with applicable fees.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation for Inactivity</h4>
                    <p className="directorshedaerp mb-3">Services may be canceled due to prolonged inactivity, with clients remaining liable for any outstanding fees.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation of Trade Transactions</h4>
                    <p className="directorshedaerp mb-3">Cancellation of trading transactions is subject to the specific terms and conditions of the relevant suppliers or manufacturers.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Communication</h4>
                    <p className="directorshedaerp mb-3">The Company will communicate the cancellation and any associated fees to clients through the agreed-upon communication channels.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation of Subscription Services</h4>
                    <p className="directorshedaerp mb-3">Cancellation of subscription-based services is subject to the specific terms outlined in the subscription agreement.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Acknowledgment</h4>
                    <p className="directorshedaerp mb-3">Clients are required to acknowledge the cancellation and associated terms in writing for the process to be considered complete.
                    </p>
                    <h4 className="policysH4 mb-3">Governing Law</h4>
                    <p className="directorshedaerp mb-3">This cancellation policy is governed by the laws of the United Arab Emirates, and any disputes arising will be resolved in accordance with these laws.
                    </p>

                    <p className="directorshedaerp mb-3">By engaging with OPAS BIZZ GENERAL TRADING L.L.C-FZ, clients acknowledge and agree to abide by the terms and conditions outlined in Cancellation Policy. (edited) </p>


                </div>
            </section>
        </Fragment>
    );
};

export default CancellationPolicy;

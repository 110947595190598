import 'jquery'; // Import jQuery
import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'owl.carousel';
import img1 from "../assets/roles.jpg"
import 'bootstrap-icons/font/bootstrap-icons.css';



const LatestRoles = () => {
  useEffect(() => {
    // Ensure $ is available when the component mounts
    const $ = window.$;

    // Initialize Owl Carousel
    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      touchDrag: true,
      navText: [
        '<i className="bi bi-arrow-left"></i>', // Left arrow icon, you can replace this with your own HTML or icon class
        '<i className="bi bi-arrow-right"></i>' // Right arrow icon, you can replace this with your own HTML or icon class
      ],
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      }
    });
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (
    <div className="containerFluid fadeInUp" style={{padding: "60px 0px 0px 0px" }}>
      <div className="container">
      <h2 className='elementor-animation-buzz' style={{font: "400 50px/60px 'Poppins'"}}>Latest Roles</h2>
        <div className="owl-carousel" style={{margintop: "30px" ,padding: "30px 30px"}}>
          <div className="cardstylt elementor-animation-buzz" style={{ color: "#fff" }}>
            <h3 className='stylRecruitment'>Project Manager</h3>
            <ul className="list_styless">
              <li>OPAS Careers</li>
              <li><i className="fa-solid fa-circle-arrow-right"></i>UAE</li>
            </ul>
            <div className="d-flex">
              <div className="fleximg">  
             
                <img className='recruitmentImg' src={img1} alt='blogimg' />
              </div>
              <div className="flex-grow-1 ms-3">
                <h4 className='recruitmentNmare'>
                Aryan
                  <br></br> <span>Group MD</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="cardstylt elementor-animation-buzz" style={{ color: "#fff" }}>
            <h3 className='stylRecruitment'>Site Engineer</h3>
            <ul className="list_styless">
              <li>OPAS Careers</li>
              <li><i className="fa-solid fa-circle-arrow-right"></i>UAE</li>
            </ul>
            <div className="d-flex">
              <div className="fleximg">  
              
                <img className='recruitmentImg' src={img1} alt='blogimg' />
              </div>
              <div className="flex-grow-1 ms-3">
                <h4 className='recruitmentNmare'>
                Vihaan
                  <br></br> <span>Group MD</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="cardstylt elementor-animation-buzz" style={{ color: "#fff" }}>
            <h3 className='stylRecruitment'>Civil Engineer</h3>
            <ul className="list_styless">
              <li>OPAS Careers</li>
              <li><i className="fa-solid fa-circle-arrow-right"></i>UAE</li>
            </ul>
            <div className="d-flex">
              <div className="fleximg">  
                <img className='recruitmentImg' src={img1} alt='blogimg' />
              </div>
              <div className="flex-grow-1 ms-3">
                <h4 className='recruitmentNmare'>
                Silas
                  <br></br> <span>Group MD</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="cardstylt elementor-animation-buzz" style={{ color: "#fff" }}>
            <h3 className='stylRecruitment'>Quantity Surveyor</h3>
            <ul className="list_styless">
              <li>OPAS Careers</li>
              <li><i className="fa-solid fa-circle-arrow-right"></i>UAE</li>
            </ul>
            <div className="d-flex">
              <div className="fleximg">  
                <img className='recruitmentImg' src={img1} alt='blogimg' />
              </div>
              <div className="flex-grow-1 ms-3">
                <h4 className='recruitmentNmare'>
                Jonah
                  <br></br> <span>Group MD</span>
                </h4>
              </div>
            </div>
          </div>

         
          
        </div>
      </div>
    </div>
  );
};

export default LatestRoles;
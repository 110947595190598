import React, { Fragment } from "react";
import Helmet from 'react-helmet';

const VisaPolicy = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Visa Policy
                </title>
                <meta
                    name="description"
                    content="Visa Policy for OPAS BIZZ GENERAL TRADING L.L.C-FZ"
                />
            </Helmet>
            <section className="bg-white pricing_banner_section policy fadeInUp">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1 policysH4">
                        Visa Policy
                    </h1>
                </div>
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px fadeInUp" style={{ padding: "100px" }}>
                <div className="section_bottom_margin ml-30 mr-30 max-w-1600px">

                    <h4 className="policysH4 mb-3">Introduction</h4>
                    <p className="directorshedaerp mb-3">This Visa Policy outlines the procedures and requirements related to visa issuance and management by OPAS BIZZ GENERAL TRADING L.L.C-&nbsp;FZ.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Application</h4>
                    <p className="directorshedaerp mb-3">Clients requiring visa services must submit a completed visa application form provided by the Company.
                    </p>
                    <h4 className="policysH4 mb-3">Eligibility Criteria</h4>
                    <p className="directorshedaerp mb-3">Visa eligibility is determined based on factors such as the purpose of travel, client background, and compliance with relevant laws.
                    </p>
                    <h4 className="policysH4 mb-3">Documentation</h4>
                    <p className="directorshedaerp mb-3">Clients are responsible for providing accurate and complete documentation as per the visa application requirements.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Types</h4>
                    <p className="directorshedaerp mb-3">The Company facilitates various visa types, including employment visas, business visas, and other categories as per U.A.E. regulations.
                    </p>
                    <h4 className="policysH4 mb-3">Job Placement Visa</h4>
                    <p className="directorshedaerp mb-3">Clients seeking job placement services must adhere to the U.A.E. labor laws, and the Company will assist in securing the necessary employment visa.</p>
                    <h4 className="policysH4 mb-3">Business Visa</h4>
                    <p className="directorshedaerp mb-3">Trading clients may apply for business visas, and the Company will provide support in obtaining the required documentation.
                    </p>
                    <h4 className="policysH4 mb-3">Submission Process</h4>
                    <p className="directorshedaerp mb-3">Visa applications are submitted to the relevant U.A.E. authorities by the Company on behalf of the client.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Processing Time</h4>
                    <p className="directorshedaerp mb-3">The processing time for visa applications may vary based on the type of visa and the U.A.E. immigration department's policies.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Fees</h4>
                    <p className="directorshedaerp mb-3">Clients are responsible for all visa-related fees, including application fees, processing fees, and any additional charges imposed by the U.A.E. authorities.
                    </p>
                    <h4 className="policysH4 mb-3">Fee Transparency</h4>
                    <p className="directorshedaerp mb-3">The Company will provide clients with a clear breakdown of all visa-related fees before initiating the application process.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Validity</h4>
                    <p className="directorshedaerp mb-3">The validity period of visas is determined by U.A.E. immigration authorities and may vary based on the visa type.
                    </p>
                    <h4 className="policysH4 mb-3">Extension of Visas</h4>
                    <p className="directorshedaerp mb-3">Clients may request visa extensions through the Company, subject to U.A.E. immigration laws.
                    </p>
                    <h4 className="policysH4 mb-3">Exit and Re-entry</h4>
                    <p className="directorshedaerp mb-3">Procedures for exit and re-entry visas will be communicated to clients, and the Company will assist in facilitating these processes.
                    </p>
                    <h4 className="policysH4 mb-3">Dependent Visas</h4>
                    <p className="directorshedaerp mb-3">Clients may inquire about dependent visas for family members, and the Company will provide guidance on the application process.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Renewals</h4>
                    <p className="directorshedaerp mb-3">The Company will notify clients in advance of visa expiration dates and assist in the renewal process, subject to U.A.E. immigration regulations.
                    </p>
                    <h4 className="policysH4 mb-3">Change of Visa Status</h4>
                    <p className="directorshedaerp mb-3">Clients seeking a change in visa status must inform the Company, and the necessary procedures will be initiated in accordance with U.A.E. laws.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Denials</h4>
                    <p className="directorshedaerp mb-3">In the event of visa denials, the Company will work with clients to understand the reasons and explore alternative solutions if applicable.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Revocation</h4>
                    <p className="directorshedaerp mb-3">Clients must adhere to U.A.E. laws to avoid visa revocation, and the Company will provide guidance on compliance.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Compliance</h4>
                    <p className="directorshedaerp mb-3">Clients are responsible for complying with U.A.E. visa regulations, and the Company will provide necessary information and assistance to ensure compliance.
                    </p>
                    <h4 className="policysH4 mb-3">Biometric Data</h4>
                    <p className="directorshedaerp mb-3">Clients may be required to provide biometric data as part of the visa application process, in accordance with U.A.E. regulations.</p>
                    <h4 className="policysH4 mb-3">Confidentiality of Visa Information</h4>
                    <p className="directorshedaerp mb-3">The Company ensures the confidentiality of client visa information and only shares it with relevant authorities for visa processing purposes.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Interview Support</h4>
                    <p className="directorshedaerp mb-3">The Company may provide guidance and support for visa interviews, as required by U.A.E. immigration authorities.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Notification</h4>
                    <p className="directorshedaerp mb-3">Clients will be notified promptly once their visa application is approved or if any issues arise during the processing.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Categories Updates</h4>
                    <p className="directorshedaerp mb-3">The Company will keep clients informed of any updates or changes to U.A.E. visa categories and requirements.</p>
                    <h4 className="policysH4 mb-3">Single and Multiple Entry Visas</h4>
                    <p className="directorshedaerp mb-3">Depending on the client's travel needs, the Company can assist in obtaining single or multiple entry visas as per U.A.E. regulations.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Validity for Trading Activities</h4>
                    <p className="directorshedaerp mb-3">Clients engaged in trading activities will be advised on the specific visa requirements and validity periods based on their business needs.
                    </p>
                    <h4 className="policysH4 mb-3">Emergency Visa Services</h4>
                    <p className="directorshedaerp mb-3">The Company may provide support for clients requiring emergency visa services, subject to the availability and policies of U.A.E. immigration authorities.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Sponsorship</h4>
                    <p className="directorshedaerp mb-3">The Company may act as the sponsor for certain visa types, and clients must adhere to sponsorship obligations as required by U.A.E. laws.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Insurance</h4>
                    <p className="directorshedaerp mb-3">Clients are encouraged to secure appropriate visa insurance to cover unforeseen circumstances affecting their ability to travel or stay in the U.A.E.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Health Requirements</h4>
                    <p className="directorshedaerp mb-3">Clients must comply with U.A.E. health requirements for visa issuance, and the Company will provide information on necessary health checks.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Advisory Services</h4>
                    <p className="directorshedaerp mb-3">The Company may offer advisory services related to visa requirements, changes in immigration policies, and other relevant matters.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Compliance Audits</h4>
                    <p className="directorshedaerp mb-3">The Company may conduct periodic audits to ensure clients' ongoing compliance with U.A.E. visa regulations.
                    </p>
                    <h4 className="policysH4 mb-3">Visa Documentation Retention</h4>
                    <p className="directorshedaerp mb-3">The Company will retain copies of visa-related documentation for a specified period in compliance with U.A.E. record-keeping laws.
                    </p>
                    <h4 className="policysH4 mb-3">Governing Law</h4>
                    <p className="directorshedaerp mb-3">This Visa Policy is governed by the laws of the United Arab Emirates, and any disputes arising will be resolved in accordance with these laws.
                    </p>

                    <p className="directorshedaerp mb-3">Clients engaging in visa-related services with OPAS BIZZ GENERAL TRADING L.L.C-FZ agree to abide by the terms and conditions outlined in this Visa Policy. It is recommended to review this policy periodically, as updates may occur to reflect changes in immigration laws or business practices.

                    </p>
                </div>
            </section>

        </Fragment>
    );
};

export default VisaPolicy;

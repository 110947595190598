import React, { Fragment } from "react";
// import PricingCard from "../components/pricing_card/PricingCard";
// import Button from "../components/UI/Button";
import Helmet from 'react-helmet';
// import FaqLanding from "../components/faq_landing/FaqLanding";
import Card from "../components/UI/Card";
// import had1 from "../assets/Meet.jpg";
import experpic1 from "../assets/experpic1.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersLine ,faPeopleGroup,faLightbulb,faCubesStacked} from '@fortawesome/free-solid-svg-icons';


const Vision = () => {
return (
    <Fragment>
    <Helmet>
        <title>
        Vision
        </title>
        <meta
        name="description"
        content="Vision"
        />
    </Helmet>

   
    <section className="containerFluid formcolor fadeInUp">
          <div className="container" style={{ paddingBottom: "120px" }}>
          <h2 className=" text-white mb-3 elementor-animation-buzz" style={{font:"400 34px/40px 'Poppins'",paddingTop:"50px"}}>
          Vision
            </h2>
            
            
            <div className="row">
              <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faUsersLine} className="innovationIcon"/>
                <h2 className="mb-3 text-white text-sm-center styh3">Prospect</h2>
                <p className="para_font_600_20px text-sm-center styp">Opas envisions a future where every individual and business sees a myriad of opportunities, creating a landscape of boundless potential.
                </p>
              </div> 
              <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faPeopleGroup} className="innovationIcon"/>
                <h2 className="mb-3 text-white text-sm-center styh3">Elevate</h2>
                <p className="para_font_600_20px text-sm-center styp">Opas aspires to elevate careers, projects, and businesses to new heights, fostering a culture of continuous growth and success in the UAE.</p>
              </div>
              <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faLightbulb} className="innovationIcon" />
              <h2 className="mb-3 text-white text-sm-center styh3">Inspire</h2>
                <p className="para_font_600_20px text-sm-center styp">Opas aims to inspire individuals and enterprises alike, fostering a spirit of creativity, resilience, and determination in the pursuit of excellence.</p>
              </div>
              <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faCubesStacked} className="innovationIcon" />
                <h2 className="mb-3 text-white text-sm-center styh3">Innovate</h2>
                <p className="para_font_600_20px text-sm-center styp">We firmly believe in looking after and rewarding our staff.</p>
              </div>
            </div>
          </div>
        </section>
        


        <section className="section_bottom_margin fadeInUp">
          <div className="d-grid-50-50 ">
            <div className="section1-2">
              <Card>
                <div className=" px-sm-30 py-sm-2rem" style={{padding:"45px"}}>
                  <h2 className="mb-3 elementor-animation-buzz" style={{font:"400 34px/40px'Poppins'"}}>Pioneering a Future of Boundless Opportunities</h2>
                  <p className="expertisePara elementor-animation-buzz">At Opas, our vision extends beyond the present, guiding us towards a future where we envision a landscape characterized by limitless opportunities. We are dedicated to pioneering innovative solutions that empower individuals, elevate businesses, and inspire a culture of continuous growth. Join us on this visionary journey as we strive to shape a landscape where success knows no bounds, and where Opas stands as a beacon of excellence and innovation in the UAE.</p>              
                  </div>
              </Card>
            </div>
            <Card extraClasses="">
              <div>
                 <img
                  className="w-100 d-block elementor-animation-buzz"
                  src={experpic1}
                  alt="visionImg"
                />
              </div>
            </Card>
          </div>
        </section>

    </Fragment>
);
};

export default Vision;

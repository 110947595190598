import React from "react";
import { Fragment } from "react";
import "swiper/css";
import Helmet from 'react-helmet';
import had1 from "../assets/Meet.jpg";
import teampic1 from "../assets/dir.jpg"
const MeetTheTeam = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
          Our Consultants
        </title>
        <meta
          name="description"
          content="Our Consultants"
        />
      </Helmet>
      <section className="inner_ban section_bottom_margin fadeInUp">
        <img src={had1} style={{ width: "100%", height: "350px" }} alt="ConsultantsImg"></img>
        {/* </Card> */}
        <div className="banner_inner" >
          <h1 className="whyhading elementor-animation-buzz">Meet the OPAS Team</h1>

        </div>


      </section>





      <section className="container-fluid fadeInUp">
        <div className="container" style={{ paddingBottom: "120px" }}>
          <h2 className="directorshedaer elementor-animation-buzz">Board of Chairman</h2>
          <p className="directorshedaerp elementor-animation-buzz">Pallavi Khobragade, as the Chairperson of OPAS Dubai, demonstrates exceptional leadership and vision, driving the organization towards achieving new heights in the business realm.</p>
          

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 paimage elementor-animation-buzz">
              <div className="card  " style={{
                backgroundImage: `url(${teampic1})`, backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '400px',
                border: "none"
              }}>
                
                <div className="card-body cardimage">
                  <h5 className="card-title cardHeading">Pallavi khobragade</h5>
                  <p className="card-text cardpara">Chairman</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>






    </Fragment>
  );
};

export default MeetTheTeam;

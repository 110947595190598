import React, { Fragment } from "react";
import Card from "../components/UI/Card";
import Helmet from 'react-helmet';
import had1 from "../assets/Meet.jpg";
import TestimonialsImg from "../assets/testimonial.png"


const Testimonials = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Testimonials</title>
        <meta
          name="description"
          content="Testimonials"
        />
      </Helmet>
      <section className="inner_ban section_bottom_margin fadeInUp">
        {/* <Card extraClasses="px-200 py-100px powering_teams"> */}
          <img src={had1} style={{width:"100%",height: "350px"}}  alt="TestimonialsImg"></img>
        {/* </Card> */}
        <div className="banner_inner" > 
        <h1 className="whyhading elementor-animation-buzz">Testimonials</h1>

        </div>


      </section>

  
      <section className="section_bottom_margin ml-30 mr-30 fadeInUp">
          <div className="text-center ">
            <div className="section1-2 mr-30 ">
            <Card extraClasses="cardborderstyle ">
            <img className="elementor-animation-buzz" src={TestimonialsImg} style={{height:"100px"}} alt="TestimonialsImg"></img>
          <p className="text-center mb-3 testimonialssty elementor-animation-buzz">
          Opas turned job hunting into a breeze! Their portal connected me with the perfect opportunity, demonstrating their commitment to excellence in career services.
          </p>
          <h2 className="elementor-animation-buzz" style={{font:"700 16px / 26px Poppins"}} >Job Placement</h2>
          </Card>
          <Card extraClasses="cardborderstyle ">
            <img className="elementor-animation-buzz" src={TestimonialsImg} style={{height:"100px"}} alt="TestimonialsImg"></img>
          <p className="text-center mb-3 testimonialssty elementor-animation-buzz" >
          Opas brought efficiency and transparency to our projects. Their skilled project managers ensured seamless execution from start to finish. Highly recommended!
          </p>
          <h2 className="elementor-animation-buzz" style={{font:"700 16px / 26px Poppins"}} alt="TestimonialsImg">Project Management</h2>
          </Card>
          <Card extraClasses="cardborderstyle  ">
            <img className="elementor-animation-buzz" src={TestimonialsImg} style={{height:"100px"}} alt="TestimonialsImg"></img>
          <p className="text-center mb-3 testimonialssty elementor-animation-buzz">
          Opas exceeded our expectations in construction. Their expertise, attention to detail, and commitment to timelines made our project a true success.
          </p>
          <h2 className="elementor-animation-buzz" style={{font:"700 16px / 26px Poppins"}} >Construction Services</h2>
          </Card>
          <Card extraClasses="cardborderstyle ">
            <img className="elementor-animation-buzz" src={TestimonialsImg} style={{height:"100px"}} alt="TestimonialsImg"></img>
          <p className="text-center mb-3 testimonialssty elementor-animation-buzz">
          Opas made international trade effortless. From sourcing to logistics, their comprehensive solutions simplified the complexities of product trading. A trustworthy partner.
          </p>
          <h2 className="elementor-animation-buzz" style={{font:"700 16px / 26px Poppins"}} >Product Trading</h2>
          </Card>
          <Card extraClasses="cardborderstyle  ">
            <img className="elementor-animation-buzz" src={TestimonialsImg} style={{height:"100px"}} alt="TestimonialsImg"></img>
          <p className="text-center mb-3 testimonialssty elementor-animation-buzz">
          Opas' job portal is a game-changer! Within days, I found the perfect job. Their user-friendly interface and diverse job listings set them apart.
          </p>
          <h2 className="elementor-animation-buzz" style={{font:"700 16px / 26px Poppins"}} >Naukri Portal</h2>
          </Card>
          <Card extraClasses="cardborderstyle  mb-5">
            <img className="elementor-animation-buzz" src={TestimonialsImg} style={{height:"100px"}} alt="TestimonialsImg"></img>
          <p className="text-center mb-3 testimonialssty elementor-animation-buzz" >
          Choosing Opas was the best decision for our business. Their comprehensive solutions, adaptability, and commitment to excellence make them an invaluable partner.
          </p>
          <h2 className="elementor-animation-buzz" style={{font:"700 16px / 26px Poppins"}} >Overall Experience</h2>
          </Card>
            </div>
            
          </div>
        </section>
    </Fragment>
  );
};

export default Testimonials;

import React, { Fragment } from "react";
import  blog1 from "../assets/blog1.jpg";
import  Blog2 from "../assets/BLOG2.png";
import had1 from "../assets/Meet.jpg";
import Blog3 from "../assets/BLOG3.jpg";
import { Link } from "react-router-dom";
const Blog = () => {
  return (
    <Fragment>

      <section className="inner_ban section_bottom_margin fadeInUp">
        <img src={had1} style={{ width: "100%", height: "350px" }} alt="blogImg"></img>
        <div className="banner_inner" >
          <h1 className="whyhading elementor-animation-buzz">News & Resources</h1>

        </div>


      </section>

      <section className="containerFluid fadeInUp" style={{ padding: "100px" }}>
        <div className="container" style={{ paddingBottom: "120px" }}>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 blogTop elementor-animation-buzz">

              <div className="blog_box blog_box1">
                <img src={blog1} alt="blogImg" />
          

                  <div className="blog_text">
                    <h5>Navigating the UAE Job Market: A Guide by Opas</h5>
                    <small>22 Nov 2023</small>
                  </div>
                  <div className="bx_overlay">
                  </div>
                  <Link to="/blogs/Navigating" className="news_link" aria-label="go_to_blog1"></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 blogTop elementor-animation-buzz">
           <div className="blog_box blog_box1">
                <img src={Blog2} alt="blogImg" />
                <div className="blog_text">
                    <h5>The Role of Project Management in Successful Constructions: Opas Insights</h5>
                    <small>22 Nov 2023</small>
                  </div>
                  <div className="bx_overlay">
                  </div>
                  <Link to="/blogs/opasInsights" className="news_link"  aria-label="go_to_blog2"></Link>
              </div>
            </div> 
            <div className="col-lg-4 col-md-4 col-sm-6 blogTop elementor-animation-buzz">
           <div className="blog_box blog_box1">
                <img src={Blog3} alt="blogImg" />
                  <div className="blog_text">
                    <h5>Trading in the UAE: Opas' Guide to International Product Trading</h5>
                    <small>22 Nov 2023</small>
                  </div>
                  <div className="bx_overlay">
                  </div>
                  <Link to="/blogs/trading" className="news_link"  aria-label="go_to_blog3"></Link>
              </div>
            </div>
          </div>
          
        </div>
      </section>

   
    </Fragment>
  );
};

export default Blog;

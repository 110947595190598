import React, { Fragment } from "react";
import Helmet from 'react-helmet';

const RefundPolicy = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Refund Policy
                </title>
                <meta
                    name="description"
                    content="Refund Policy for OPAS BIZZ GENERAL TRADING L.L.C-FZ"
                />
            </Helmet>
            <section className="bg-white pricing_banner_section policy fadeInUp">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1 policysH4">
                        Refund Policy
                    </h1>
                </div>
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px fadeInUp" style={{ padding: "100px" }}>
                <div className="section_bottom_margin ml-30 mr-30 max-w-1600px">
                    <h4 className="policysH4 mb-3">Introduction</h4>
                    <p className="directorshedaerp mb-3">This Refund Policy outlines the terms and conditions regarding refunds for services provided by OPAS BIZZ GENERAL TRADING L.L.C-FZ.
                    </p>
                    <h4 className="policysH4 mb-3">No Refund Policy</h4>
                    <p className="directorshedaerp mb-3">All transactions and payments made to OPAS BIZZ GENERAL TRADING L.L.C-FZ are non-refundable.
                    </p>
                    <h4 className="policysH4 mb-3">Scope</h4>
                    <p className="directorshedaerp mb-3">This policy applies to all services, job placement, and trading activities facilitated by the Company.
                    </p>
                    <h4 className="policysH4 mb-3">Clear Understanding</h4>
                    <p className="directorshedaerp mb-3">Clients are advised to carefully read and understand this policy before engaging in any transactions.
                    </p>
                    <h4 className="policysH4 mb-3">Service Confirmation</h4>
                    <p className="directorshedaerp mb-3">Service fees are considered earned upon confirmation of service commencement, and no refunds will be provided thereafter.
                    </p>
                    <h4 className="policysH4 mb-3">Change of Mind</h4>
                    <p className="directorshedaerp mb-3">Refunds will not be granted for a change of mind, change in business circumstances, or any other reasons beyond the Company's control.
                    </p>
                    <h4 className="policysH4 mb-3">Cancellation Fees</h4>
                    <p className="directorshedaerp mb-3">In case of cancellation, clients may be liable for cancellation fees as per the terms agreed upon during the initial engagement.
                    </p>
                    <h4 className="policysH4 mb-3">Force Majeure</h4>
                    <p className="directorshedaerp mb-3">In the event of force majeure events or circumstances beyond the control of the Company or the client, no refunds will be issued.
                    </p>
                    <h4 className="policysH4 mb-3">Payment Disputes</h4>
                    <p className="directorshedaerp mb-3">Disputes over service fees do not warrant a refund, and clients are encouraged to address concerns through communication.
                    </p>
                    <h4 className="policysH4 mb-3">No Show</h4>
                    <p className="directorshedaerp mb-3">In cases of no-show or failure to comply with agreed-upon terms, no refunds will be provided.
                    </p>
                    <h4 className="policysH4 mb-3">Partial Services</h4>
                    <p className="directorshedaerp mb-3">Clients are not entitled to a refund for partial services or if the full scope of services is not utilized.
                    </p>
                    <h4 className="policysH4 mb-3">Termination by Client</h4>
                    <p className="directorshedaerp mb-3">If the client terminates services prematurely, no refunds will be issued for the remaining period.
                    </p>
                    <h4 className="policysH4 mb-3">Termination by Company</h4>
                    <p className="directorshedaerp mb-3">In the event of termination by the Company due to a breach of terms, no refunds will be provided.
                    </p>
                    <h4 className="policysH4 mb-3">Client Responsibilities</h4>
                    <p className="directorshedaerp mb-3">Clients are responsible for ensuring accurate information is provided, and any inaccuracies do not warrant a refund.
                    </p>
                    <h4 className="policysH4 mb-3">Promotional Services</h4>
                    <p className="directorshedaerp mb-3">Refunds will not be provided for services offered as part of promotional or discounted packages.
                    </p>
                    <h4 className="policysH4 mb-3">Expiration of Services</h4>
                    <p className="directorshedaerp mb-3">Clients are responsible for utilizing services within the specified timeframe, and no refunds will be issued for expired services.
                    </p>
                    <h4 className="policysH4 mb-3">Third-Party Services</h4>
                    <p className="directorshedaerp mb-3">Fees paid for third-party services or expenses are non-refundable, subject to the third party's refund policy.
                    </p>
                    <h4 className="policysH4 mb-3">Documentation Fees</h4>
                    <p className="directorshedaerp mb-3">Any fees associated with documentation or processing are non-refundable.
                    </p>
                    <h4 className="policysH4 mb-3">Currency Fluctuations</h4>
                    <p className="directorshedaerp mb-3">Refunds will not be adjusted for changes in currency exchange rates.
                    </p>
                    <h4 className="policysH4 mb-3">Service Customization</h4>
                    <p className="directorshedaerp mb-3">Fees for customized services are non-refundable, given the tailored nature of the services provided.
                    </p>
                    <h4 className="policysH4 mb-3">Unforeseen Circumstances</h4>
                    <p className="directorshedaerp mb-3">Refunds will not be granted for services affected by unforeseen circumstances, including government regulations, natural disasters, or pandemics.
                    </p>
                    <h4 className="policysH4 mb-3">Product Returns</h4>
                    <p className="directorshedaerp mb-3">Trading of goods is subject to the specific return policies of the suppliers and manufacturers. No refunds are guaranteed by OPAS BIZZ GENERAL TRADING L.L.C-FZ.
                    </p>
                    <h4 className="policysH4 mb-3">Client Acknowledgment</h4>
                    <p className="directorshedaerp mb-3">Clients acknowledge and agree to the no-refund policy at the time of engaging in services with the Company.</p>
                    <h4 className="policysH4 mb-3">Dispute Resolution</h4>
                    <p className="directorshedaerp mb-3">Disputes regarding refunds will be resolved in accordance with the dispute resolution mechanisms outlined in the general terms and conditions.
                    </p>
                    <h4 className="policysH4 mb-3">Written Requests</h4>
                    <p className="directorshedaerp mb-3">Any request for a refund must be submitted in writing, providing clear details and justification for the request.
                    </p>
                    <h4 className="policysH4 mb-3">Non-Transferable</h4>
                    <p className="directorshedaerp mb-3">Refunds are non-transferable and can only be issued to the original payer.
                    </p>
                    <h4 className="policysH4 mb-3">No Refund for Inactivity</h4>
                    <p className="directorshedaerp mb-3">No refunds will be provided for services that remain inactive due to client inaction or delay.
                    </p>
                    <h4 className="policysH4 mb-3">Gift Cards and Vouchers</h4>
                    <p className="directorshedaerp mb-3">Refunds will not be issued for the purchase of gift cards, vouchers, or any other prepaid services.
                    </p>
                    <h4 className="policysH4 mb-3">Unclaimed Refunds</h4>
                    <p className="directorshedaerp mb-3">Refunds that remain unclaimed for an extended period will be forfeited.
                    </p>
                    <h4 className="policysH4 mb-3">Bank Charges</h4>
                    <p className="directorshedaerp mb-3">Any bank or transaction charges incurred during the refund process will be borne by the client.
                    </p>
                    <h4 className="policysH4 mb-3">Review of Refund Requests</h4>
                    <p className="directorshedaerp mb-3">Refund requests will be reviewed on a case-by-case basis, and decisions made by the Company are final.
                    </p>
                    <h4 className="policysH4 mb-3">Refund Processing Time</h4>
                    <p className="directorshedaerp mb-3">If a refund is approved, the processing time may vary, and clients will be notified accordingly.
                    </p>
                    <h4 className="policysH4 mb-3">Damaged Goods</h4>
                    <p className="directorshedaerp mb-3">In the case of trading activities involving physical goods, no refunds will be provided for goods damaged after delivery.
                    </p>
                    <h4 className="policysH4 mb-3">Client Accountability</h4>
                    <p className="directorshedaerp mb-3">Clients are accountable for reviewing and confirming service details before making any payments.
                    </p>
                    <h4 className="policysH4 mb-3">Discontinuation of Services</h4>
                    <p className="directorshedaerp mb-3">The Company reserves the right to discontinue services if clients fail to adhere to the terms and conditions, with no obligation to provide a refund.

                    </p>

                </div>
            </section>
        </Fragment>
    );
};

export default RefundPolicy;

import React from 'react'
import classes from './Card.module.css';
const Card = (props) => {
  var cardClasses = classes.card;
  if(props.boxshadow === '1'){
    cardClasses = cardClasses + ` ${classes.box_shadow}`
  }

  if(props.extraClasses){
    cardClasses = cardClasses + ` ${props.extraClasses}`

  }
  return (
    <div className={cardClasses} id={props.id}>
    {props.children}
    </div>
  )
}

export default Card
import 'jquery'; // Import jQuery
import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'owl.carousel';

const Map = () => {
  useEffect(() => {
    // Ensure $ is available when the component mounts
    const $ = window.$;

    // Initialize Owl Carousel
    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      touchDrag: true,
          autoplay: true, // Enable autoplay
      autoplayTimeout: 2000, // Set autoplay timeout in milliseconds (e.g., 3000 for 3 seconds)

      navText: [
        // '<i class="bi bi-arrow-left"></i>', // Left arrow icon, you can replace this with your own HTML or icon class
        // '<i class="bi bi-arrow-right"></i>' // Right arrow icon, you can replace this with your own HTML or icon class
      ],
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (
    <div className="containerFluid fadeInUp" style={{ backgroundColor: "#000", padding: "60px 0px" }}>
      <div className="container" style={{ paddingTop: "30px" }}>
        <div className="owl-carousel">
          {/* Add your card components here */}
          <div className="" style={{ color: "#fff" }}>
            <p style={{ font: " 700 24px/33px 'Poppins'" }}>Dubai, U.A.E.</p>
            <p className='fontColorOwl'>Address :-</p>
            <p className='fontColorOwl'>Business Center 1, M Floor,</p>
            <p className='fontColorOwl fontColorOwlTop'>M Floor,</p>
            <p className='fontColorOwl fontColorOwlTop'>The Meydan Hotel,</p>
            <p className='fontColorOwl fontColorOwlTop'>Nad Al Sheba</p>
            <p className='fontColorOwl'>+971502342494</p>
            <p className='fontColorOwl' style={{ marginTop: "-10px" }}>enquiry@opas.ae</p>

          </div>
          <div className="" style={{ color: "#fff" }}>
            <p style={{ font: " 700 24px/33px 'Poppins'" }}>اوباس بيز جينرال تريدنج ش.ذ.م.م-منطقة حرة</p>
            <p className='fontColorOwl'>عنوان :-</p>
            <p className='fontColorOwl'>مركز الأعمال 1</p>
            <p className='fontColorOwl fontColorOwlTop'> طابق الميزانين،</p>
            <p className='fontColorOwl fontColorOwlTop'>فندق الميدان</p>
            <p className='fontColorOwl fontColorOwlTop'>ند الشبا، دبي</p>
            <p className='fontColorOwl fontColorOwlTop'>الإمارات العربية المتحدة</p>
            <p className='fontColorOwl'>٠٠٩٧١ ٥٠ ٢٣٤ ٢٤٩٤</p>

            <p className='fontColorOwl' style={{ marginTop: "-10px" }}>استفسارات@opas.ae</p>

          </div>
          {/* Add more cards as needed */}
        </div>
      </div>
    </div>
  );
};

export default Map;

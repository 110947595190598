import React from "react";
import { Fragment } from "react";
import Card from "../components/UI/Card";
import "swiper/css";
import Helmet from "react-helmet";
import had1 from "../assets/Meet.jpg";
import experpic1 from "../assets/experpic1.webp"
import experpic2 from "../assets/experpic2.webp"
import LatestRoles from "./LatestRoles";

const OurProject = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Our Project</title>
        <meta
          name="description"
          content="Our Project"
        />
      </Helmet>
      <section className="inner_ban section_bottom_margin  fadeInUp ">
        {/* <Card extraClasses="px-200 py-100px powering_teams"> */}
          <img src={had1} style={{width:"100%",height: "350px"}} alt="imgbaner"></img>
        {/* </Card> */}
        <div className="banner_inner" > 
        <h1 className="whyhading elementor-animation-buzz">Our Project</h1>

        </div>


      </section>

      <section className="section_bottom_margin fadeInUp">
          <div className="d-grid-50-50 ">
            <div className="section1-2">
              <Card>
                <div className="pb-120px px-sm-30 py-sm-2rem">
                  <h2 className="mb-3 elementor-animation-buzz" style={{font:"400 34px/40px'Poppins'"}}>Job/Naukri Portal Enhancement</h2>
                  <p className="expertisePara elementor-animation-buzz">We are a specialist Construction & Real Estate recruitment specialist with a local presence and global reach. Our Dubai office was established in early 2008 and trades across the Middle East, North Africa, and Asia. We are true experts in our field with the SSA Middle Eastern team having a  combined total of 20+ years’ experience trading in the MENA & APAC  Construction Market.</p>
                                  
                                  
                  </div>
              </Card>
            </div>
            <Card extraClasses="">
              <div>
                 <img
                  className="w-100 d-block elementor-animation-buzz"
                  src={experpic1}  

                  alt="imagelogo"
                />
              </div>
            </Card>
          </div>
        </section>
        <LatestRoles />
        <section className="section_bottom_margin fadeInUp">
          <div className="d-grid-50-50 ">
            <Card extraClasses="">
              <div>
                 <img
                  className="w-100 d-block elementor-animation-buzz"
                  src={experpic2}
                  alt="imagebaner"
                />
              </div>
            </Card>
            <div className="section1-2">
              <Card>
                <div className="pb2-120px px-sm-30 py-sm-2rem">
                  <h2 className="elementor-animation-buzz mb-3" style={{font:"400 34px/40px'Poppins'"}}>International Product Trading Platform</h2>
                  <p className="mb-2 para_font_400_16px expertisePara elementor-animation-buzz">Opas might engage in a project to establish a comprehensive platform for international product trading, simplifying processes for businesses involved in import/export activities.</p>
                    <ul className="expertisePara" style={{marginLeft:"20px"}}>
                      <li className="elementor-animation-buzz">One-Stop Solution</li>
                      <li className="elementor-animation-buzz">Expert Guidance</li>
                      <li className="elementor-animation-buzz">Innovation at Every Step</li>
                      
                     
                    </ul>
                     
                  </div>
              </Card>
            </div>
          </div>
        </section>
    </Fragment>
  );
};

export default OurProject;

import { Fragment, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import OurCompany from "./pages/OurCompany";
import MeetTheTeam from "./pages/MeetTheTeam";
import Navbar from "./components/navbar/Navbar";
import SsaCareers from "./pages/SsaCareers";
import Testimonials from "./pages/Testimonials";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import LandingNav from "./components/landing_nav/LandingNav";
import Page404 from "./pages/404";
import Privacypolicy from "./pages/Privacypolicy";
import TermsofService from "./pages/TermsofService";
import RefundPolicy from "./pages/RefundPolicy";
import CancellationPolicy from "./pages/CancellationPolicy";
import Vision from "./pages/Vision";
import OurProject from "./pages/OurProject";
import VisaPolicy from "./pages/VisaPolicy";
import Mission from "./pages/Mission";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";
import Blog3 from "./pages/Blog3";

function App() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Fragment>
      {location.pathname === '/Landing' ? <LandingNav/> : <Navbar /> }
      <Routes>
        <Route path="/meet-the-team" element={<MeetTheTeam />} />
        <Route path="/" element={<OurCompany />} />
        <Route path="/opas-careers" element={<SsaCareers />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termsandConditions" element={<TermsofService />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/cancellationpolicy" element={<CancellationPolicy />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/visapolicy" element={<VisaPolicy />} />
        <Route path="/blogs/Navigating" element={<Blog1 />} />
        <Route path="/blogs/opasInsights" element={<Blog2 />} />
        <Route path="/blogs/trading" element={<Blog3 />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/ourproject" element={<OurProject />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
      {location.pathname === '/' ? <Footer /> : <Footer /> }

    </Fragment>
  );
}

export default App;

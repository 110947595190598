import React, { Fragment } from "react";
import Helmet from 'react-helmet';

const Privacypolicy = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Privacy Policy
                </title>
                <meta
                    name="description"
                    content="Privacy Policy for OPAS BIZZ GENERAL TRADING L.L.C-FZ"
                />
            </Helmet>
            <section className="bg-white pricing_banner_section policy fadeInUp">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1 policysH4">
                        Privacy Policy
                    </h1>
                </div>
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px fadeInUp" style={{ padding: "100px" }}>
                <div className="section_bottom_margin ml-30 mr-30 max-w-1600px">
                    <h4 className="policysH4 mb-3">Introduction</h4>
                    <p className="directorshedaerp mb-3">This Privacy Policy outlines how OPAS BIZZ GENERAL TRADING L.L.C-FZ collects, uses, and protects personal information.</p>
                    <h4 className="policysH4 mb-3">Information Collection</h4>
                    <p className="directorshedaerp mb-3">We collect personal information such as names, addresses, contact details, and other relevant data necessary for our business operations.</p>
                    <h4 className="policysH4 mb-3">Legal Basis</h4>
                    <p className="directorshedaerp mb-3">We process personal information based on legal grounds, including consent, contract performance, and compliance with legal obligations.</p>
                    <h4 className="policysH4 mb-3">Data Use</h4>
                    <p className="directorshedaerp mb-3">Personal information is used for providing services, job placement, and facilitating trading activities in accordance with applicable laws.</p>
                    <h4 className="policysH4 mb-3">Data Retention</h4>
                    <p className="directorshedaerp mb-3">We retain personal information for the duration required to fulfill the purposes outlined in this Privacy Policy and comply with legal obligations.</p>
                    <h4 className="policysH4 mb-3">Data Security</h4>
                    <p className="directorshedaerp mb-3">We implement security measures to protect personal information from unauthorized access, disclosure, alteration, and destruction.</p>
                    <h4 className="policysH4 mb-3">Information Sharing</h4>
                    <p className="directorshedaerp mb-3">Personal information may be shared with third parties, such as business partners, service providers, or regulatory authorities, as required by law.</p>
                    <h4 className="policysH4 mb-3">International Data Transfers</h4>
                    <p className="directorshedaerp mb-3">Personal information may be transferred internationally for business purposes, ensuring compliance with data protection laws.</p>
                    <h4 className="policysH4 mb-3">Cookies and Tracking Technologies</h4>
                    <p className="directorshedaerp mb-3">We use cookies and similar technologies to enhance user experience, track website usage, and collect information for analytics.</p>
                    <h4 className="policysH4 mb-3">Marketing Communications</h4>
                    <p className="directorshedaerp mb-3">Clients may receive marketing communications, and they have the option to opt out or unsubscribe from such communications.</p>
                    <h4 className="policysH4 mb-3">User Rights</h4>
                    <p className="directorshedaerp mb-3">Users have the right to access, rectify, delete, or restrict the processing of their personal information, as permitted by law.</p>
                    <h4 className="policysH4 mb-3">Children's Privacy</h4>
                    <p className="directorshedaerp mb-3">Our services are not intended for children under 13 years of age, and we do not knowingly collect personal information from them.
                    </p>
                    <h4 className="policysH4 mb-3">Data Accuracy</h4>
                    <p className="directorshedaerp mb-3">We strive to maintain accurate and up-to-date personal information and encourage users to notify us of any changes.
                    </p>
                    <h4 className="policysH4 mb-3">Notification of Changes</h4>
                    <p className="directorshedaerp mb-3">Users will be informed of any changes to this Privacy Policy through our website or other communication channels.</p>
                    <h4 className="policysH4 mb-3">Third-Party Links</h4>
                    <p className="directorshedaerp mb-3">Our website may contain links to third-party websites, and we are not responsible for their privacy practices. Users should review the privacy policies of these external sites.
                    </p>
                    <h4 className="policysH4 mb-3">Sensitive Information</h4>
                    <p className="directorshedaerp mb-3">We do not knowingly collect or process sensitive personal information, such as race, religion, or health details, unless required by law.
                    </p>
                    <h4 className="policysH4 mb-3">Data Portability</h4>
                    <p className="directorshedaerp mb-3">Users have the right to receive their personal information in a structured, commonly used, and machine-readable format.
                    </p>
                    <h4 className="policysH4 mb-3">Compliance with Data Protection Laws</h4>
                    <p className="directorshedaerp mb-3">We comply with applicable data protection laws, including the General Data Protection Regulation (GDPR) and relevant U.A.E. regulations.
                    </p>
                    <h4 className="policysH4 mb-3">Contact Information</h4>
                    <p className="directorshedaerp mb-3">Users can contact us at any time using the provided contact details to inquire about their personal information or this Privacy Policy.
                    </p>
                    <h4 className="policysH4 mb-3">Data Deletion Requests</h4>
                    <p className="directorshedaerp mb-3">Users may request the deletion of their personal information, subject to legal requirements and legitimate business needs.
                    </p>
                    <h4 className="policysH4 mb-3">Data Breach Response</h4>
                    <p className="directorshedaerp mb-3">In the event of a data breach, we will promptly investigate, take appropriate measures, and notify affected individuals and regulatory authorities, as required by law.
                    </p>
                    <h4 className="policysH4 mb-3">Privacy by Design</h4>
                    <p className="directorshedaerp mb-3">We implement privacy considerations into the design and development of our products and services.
                    </p>
                    <h4 className="policysH4 mb-3">Employee Training</h4>
                    <p className="directorshedaerp mb-3">Our employees are trained on privacy matters, emphasizing the importance of safeguarding personal information.
                    </p>
                    <h4 className="policysH4 mb-3">Anonymous Data</h4>
                    <p className="directorshedaerp mb-3">We may aggregate and anonymize data for statistical analysis and reporting purposes.
                    </p>
                    <h4 className="policysH4 mb-3">Data Processing Agreements</h4>
                    <p className="directorshedaerp mb-3">When sharing personal information with third parties, we ensure the existence of data processing agreements compliant with applicable laws.
                    </p>
                    <h4 className="policysH4 mb-3">Data Protection Officer</h4>
                    <p className="directorshedaerp mb-3">We have designated a Data Protection Officer responsible for overseeing privacy matters.
                    </p>
                    <h4 className="policysH4 mb-3">Cross-Border Data Transfers</h4>
                    <p className="directorshedaerp mb-3">Personal information may be transferred across borders as necessary for business operations, with appropriate safeguards in place.
                    </p>
                    <h4 className="policysH4 mb-3">Customer Authentication</h4>
                    <p className="directorshedaerp mb-3">We employ secure authentication methods to verify the identity of users accessing personal information.
                    </p>
                    <h4 className="policysH4 mb-3">Incident Response Plan</h4>
                    <p className="directorshedaerp mb-3">We have an incident response plan in place to effectively handle and mitigate any breaches of personal information.
                    </p>
                    <h4 className="policysH4 mb-3">Monitoring and Auditing</h4>
                    <p className="directorshedaerp mb-3">We conduct regular monitoring and auditing to ensure compliance with this Privacy Policy and applicable data protection laws.
                    </p>
                    <h4 className="policysH4 mb-3">User Consent</h4>
                    <p className="directorshedaerp mb-3">Users provide explicit consent for the processing of their personal information for specified purposes.
                    </p>
                    <h4 className="policysH4 mb-3">Data Encryption</h4>
                    <p className="directorshedaerp mb-3">Personal information is encrypted during transmission and storage to enhance data security.
                    </p>
                    <h4 className="policysH4 mb-3">Customer Feedback</h4>
                    <p className="directorshedaerp mb-3">We welcome user feedback regarding privacy concerns and continuously strive to enhance our privacy practices.
                    </p>
                    <h4 className="policysH4 mb-3">Data Minimization</h4>
                    <p className="directorshedaerp mb-3">We only collect and process personal information that is necessary for the intended purpose.
                    </p>
                    <h4 className="policysH4 mb-3">Biometric Data</h4>
                    <p className="directorshedaerp mb-3">We do not collect or process biometric data without explicit consent and compliance with applicable laws.
                    </p>
                    <h4 className="policysH4 mb-3">Record of Processing Activities</h4>
                    <p className="directorshedaerp mb-3">We maintain a record of our data processing activities in accordance with data protection regulations.
                    </p>
                    <h4 className="policysH4 mb-3">Data Subject Requests</h4>
                    <p className="directorshedaerp mb-3">Users can submit requests regarding their personal information, and we will respond within the legally required timeframe.
                    </p>
                    <h4 className="policysH4 mb-3">Employee Confidentiality Agreements</h4>
                    <p className="directorshedaerp mb-3">Employees sign confidentiality agreements to protect the privacy of personal information they may access during their employment.
                    </p>
                    <h4 className="policysH4 mb-3">Data Disposal</h4>
                    <p className="directorshedaerp mb-3">Personal information is securely disposed of when it is no longer needed for the purposes for which it was collected.
                    </p>
                    <h4 className="policysH4 mb-3">International Privacy Standards</h4>
                    <p className="directorshedaerp mb-3">We adhere to international privacy standards and best practices in addition to local data protection laws.
                    </p>
                    <h4 className="policysH4 mb-3">Social Media Interactions</h4>
                    <p className="directorshedaerp mb-3">Interactions with our social media accounts are subject to the privacy policies of the respective platforms.
                    </p>
                    <h4 className="policysH4 mb-3">Security Incident Reporting</h4>
                    <p className="directorshedaerp mb-3">Users are encouraged to report any suspected security incidents or privacy breaches promptly.
                    </p>
                    <h4 className="policysH4 mb-3">Automated Decision-Making</h4>
                    <p className="directorshedaerp mb-3">We do not solely rely on automated decision-making processes that significantly impact individuals without human intervention.
                    </p>
                    <h4 className="policysH4 mb-3">Vendor Privacy Assurance</h4>
                    <p className="directorshedaerp mb-3">Third-party vendors involved in processing personal information on our behalf are required to adhere to privacy and security standards.
                    </p>
                    <h4 className="policysH4 mb-3">Whistleblower Protection</h4>
                    <p className="directorshedaerp mb-3">We have mechanisms in place to protect individuals who report privacy concerns or breaches.
                    </p>
                    <h4 className="policysH4 mb-3">Health and Safety Data</h4>
                    <p className="directorshedaerp mb-3">Any health and safety data collected is handled in compliance with privacy laws and regulations.
                    </p>
                    <h4 className="policysH4 mb-3">Social Responsibility Data</h4>
                    <p className="directorshedaerp mb-3">Social responsibility initiatives involving personal information are conducted in accordance with privacy principles.
                    </p>
                    <h4 className="policysH4 mb-3">Data Access Restrictions</h4>
                    <p className="directorshedaerp mb-3">Access to personal information is restricted to authorized personnel with a legitimate need for such access.
                    </p>
                    <h4 className="policysH4 mb-3">Employee Awareness Programs</h4>
                    <p className="directorshedaerp mb-3">We conduct ongoing employee awareness programs to ensure a deep understanding of privacy and security principles.
                    </p>
                    <h4 className="policysH4 mb-3">Customer Education</h4>
                    <p className="directorshedaerp mb-3">We provide educational resources to customers on how their personal information is handled and protected.
                    </p>

                    <p className="directorshedaerp mb-3">By engaging with OPAS BIZZ GENERAL TRADING L.L.C-FZ, users acknowledge that they have read and understood this comprehensive Privacy Policy. It is recommended to review this policy periodically, as updates may occur to reflect changes in business practices or legal requirements.</p>

                </div>
            </section>
        </Fragment>
    );
};

export default Privacypolicy;

  import React, { Fragment } from "react";
  import classes from "./Footer.module.css";
  import {FaFacebookSquare, FaLinkedin ,FaPhoneAlt,FaMailBulk, FaInstagramSquare} from "react-icons/fa";
  import { Link } from "react-router-dom";
  import footerLogo from "../../assets/logoops.png"
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import {faCircleArrowRight} from '@fortawesome/free-solid-svg-icons';
  
  const Footer = () => {
    
    return (
      <Fragment>
        {/* <section className={classes.request_demo}>
          <p className={classes.request_demo_text}>{Data["footerParagraph"][0]}
          </p>
          <Link to="/contact-us">
          <Button title= {Data["footerParagraph"][1]} /></Link>
        </section> */}





  <div className="containerFluid"  style={{background:"#506175",color:"white"}}>
  <div className="container" >
    <footer className="pt-5">
      <div className="row">
      <div className="col-lg-6 col-md-6 mb-3">
          <form>
          <img className="elementor-animation-buzz" src={footerLogo} style={{height:"100px"}} alt="webiste_logo" />
          <div className="" style={{marginTop:"40px"}}>
          <h3 className={classes.footer_heading} style={{marginTop:"15px",display:"inline"}}><FaPhoneAlt style={{marginRight:"20px",color: "#038ee2"}}/><span className="footerHeading"><a href="tel:+971502342494" style={{textDecoration: "none",color: "#038EE2"}}>+971502342494</a></span></h3>
            <h3 className={classes.footer_heading} style={{marginTop:"10px"}}> <FaMailBulk style={{marginRight:"19px",color: "#038ee2"}} /><span className="footerHeading "><a href="mailto:enquiry@opas.ae" style={{textDecoration: "none",color: "#038EE2"}}>enquiry@opas.ae</a></span></h3>
          </div>
            
            <div className="d-flex flex-column flex-sm-row w-100 gap-2">
            <ul className={classes.footer_ul} style={{marginTop:"15px",display:"flex"}}>
                <li className="footerlihover elementor-animation-buzz" style={{marginTop:"10px"}}>
                 <Link to="https://www.facebook.com/" > <span><FaFacebookSquare style={{fontSize:"40px"}} /> </span> </Link>
                  {/* <span><FaLinkedin style={{marginLeft:"10px",fontSize:"40px"}} /></span>
                 <span><FaInstagramSquare style={{marginLeft:"10px",fontSize:"40px"}} /></span> */}
                </li>
                <li className="footerlihover elementor-animation-buzz" style={{marginTop:"10px"}}>
               <Link to="https://in.linkedin.com/"><span><FaLinkedin style={{marginLeft:"30px",fontSize:"40px"}} /></span></Link>
                </li>
                <li className="footerlihover elementor-animation-buzz" style={{marginTop:"10px"}}>
               <Link to="https://www.instagram.com/"><span><FaInstagramSquare style={{marginLeft:"30px",fontSize:"40px"}} /></span></Link> 
                </li>
              </ul> 
            </div>
          </form>
        </div>
        
          <div className="col-lg-2 col-md-6 mb-3">
        <h3 className="elementor-animation-buzz" style={{color:"white",paddingBottom:"15px",marginTop:"60px",font: "700 16px/26px 'Poppins'"}}>Our Policy</h3>
              <ul className={classes.footer_ul}>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/termsandConditions" className="footerli"><span className="elementor-animation-buzz">Terms and Conditions</span></Link></li>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/privacypolicy" className="footerli">Privacy Policy</Link></li>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/refundpolicy" className="footerli">Refund Policy</Link></li>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/cancellationpolicy" className="footerli">Cancellation Policy</Link></li>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/visapolicy" className="footerli">Visa Policy</Link></li>
              </ul>
        </div>
        <div className="col-lg-2 col-md-3 mb-3">
        <h3 className="elementor-animation-buzz" style={{color:"white",paddingBottom:"15px",marginTop:"60px",font: "700 16px/26px 'Poppins'"}}>Our Company</h3>
              <ul className={classes.footer_ul}>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/" className="footerli">For Clients</Link></li>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/meet-the-team" className="footerli">Meet The Team</Link></li>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/mission" className="footerli">Mission</Link></li>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/vision" className="footerli">Vision</Link></li>
              
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="/testimonials" className="footerli">Testimonials</Link></li>
              </ul>
        </div>

        <div className="col-lg-2 col-md-3 mb-3">
        <h3 className="elementor-animation-buzz" style={{color:"white",paddingBottom:"15px",marginTop:"60px",font: "700 16px/26px 'Poppins'"}}>Job Seekers</h3>
              <ul className={classes.footer_ul}>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="https://jobs.opas.ae" className="footerli">Job Seeker Info</Link></li>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="https://jobs.opas.ae" className="footerli">Browse Jobs</Link></li>
              <li className="elementor-animation-buzz"><FontAwesomeIcon icon={faCircleArrowRight} className="navbar__div__link_dropdown_icon2133" /><Link to="https://jobs.opas.ae" className="footerli">Upload Your CV</Link></li>
              </ul>
        </div>

        
      </div>

      <div className="copyright"> 
          <div>
            <p  className="copyrightP">Copyright ©2023 OPAS LTD. Privacy Notice</p>
            </div>
          </div>
    </footer>
  </div>

  </div>



  {/* 
        <footer className={classes.main_footer } style={{background:"#506175",color:"white"}}>
          <p className={classes.footer_top__text}>
            is designed for your organisation, for your people and everything
            in between!
          </p>
          <div className="text-center mt-5 mb-5">
            <a
              className={classes.btn_footer}
              href=""
            >
              <GrAppleAppStore /> AppleStore
            </a>
            <a
              className={googlePlayClasses}
              href=""
            >
              <FaGooglePlay /> PlayStore
            </a>
          </div>
          <div className={classes.footer_links + ' max-w-1600px'}>
            <div style={{marginTop:"60px"}}>
              <img src={footerLogo} />
            <h3 className={classes.footer_heading} style={{marginTop:"15px"}}><FaPhoneAlt style={{marginRight:"20px"}}/><span className="footerHeading">+971 (0)4 325 5033</span></h3>
              <ul className={classes.footer_ul} style={{marginTop:"15px"}}>
                <li className="footerlihover" style={{marginTop:"10px"}}>
                  <span><FaFacebookSquare /> </span>  <span><FaLinkedin style={{marginLeft:"10px"}} /></span> <span><FaInstagramSquare style={{marginLeft:"10px"}} /></span>
                </li>
              </ul> 
            </div>

            <div className="forMoblie">
          
              <h3 className={classes.footer_heading} style={{marginTop:"135px"}}> <FaMailBulk style={{marginRight:"19px"}} /><span className="footerHeading ">enquiries.com@ssaltd.com</span></h3>
              <ul className={classes.footer_ul}>
                <li>
                  <Link to="/about-us">{Data["footerli"][4][1]}</Link>
                </li>
                <li>
                  <Link to="/customers">{Data["footerli"][4][2]}</Link>
                </li>
                <li>
                  <Link to="/careers">{Data["footerli"][4][3]}</Link>
                </li>
                <li>
                  <Link to="/about-us">{Data["footerli"][4][4]}</Link>
                </li>
              </ul>
            </div>
            <div>
          
              <h3 className={classes.footer_heading}>{Data["footerli"][1][0]}</h3>
              <ul className={classes.footer_ul}>
              <li><Link to="/blogs">{Data["footerli"][1][1]}</Link></li>
              <li><Link to="/faqs">{Data["footerli"][1][2]}</Link></li>
              <li><Link to="/gallary">{Data["footerli"][1][3]}</Link></li>
              </ul>
            </div>
            <div>
              <h3 style={{color:"white",paddingBottom:"40px",marginTop:"60px",font: "700 16px/26px 'Poppins'"}}>Our Company</h3>
              <ul className={classes.footer_ul}>
              <li><AiOutlineLeftCircle className="navbar__div__link_dropdown_icon2133" /><Link to="/contact-us" className="footerli">For Clients</Link></li>
              <li><AiOutlineLeftCircle className="navbar__div__link_dropdown_icon2133" /><Link to="/contact-us" className="footerli">Meet The Team</Link></li>
              <li><AiOutlineLeftCircle className="navbar__div__link_dropdown_icon2133" /><Link to="/contact-us" className="footerli">SSA Careers</Link></li>
              <li><AiOutlineLeftCircle className="navbar__div__link_dropdown_icon2133" /><Link to="/contact-us" className="footerli">Testimonials</Link></li>
              </ul>
            </div>
            <div>


              <h3 style={{color:"white",paddingBottom:"40px",marginTop:"60px",font: "700 16px/26px 'Poppins'"}}>Job Seekers</h3>
              <ul className={classes.footer_ul}>
              <li><AiOutlineLeftCircle className="navbar__div__link_dropdown_icon2133" /><Link to="/privacypolicy" className="footerli">Job Seeker Info</Link></li>
              <li><AiOutlineLeftCircle className="navbar__div__link_dropdown_icon2133" /><Link to="/termsofservice" className="footerli">Browse Jobs</Link></li>
              <li><AiOutlineLeftCircle className="navbar__div__link_dropdown_icon2133" /><Link to="/refundpolicy" className="footerli">Upload Your CV</Link></li>
              </ul>
            </div>
            
          </div>
          <div className="copyright"> 
          <div>
            <p  className="copyrightP">Copyright ©2023 SSA LTD. Privacy Notice</p>
            </div>
          </div>
        </footer> */}
        
      </Fragment>
    );
  };

  export default Footer;

import React from "react";
import classes from "./Testimonial.module.css";
const Testimonial = (props) => {
  return (
    <div className={classes.single_testimonial}>
      <img
          className={`${classes.testimonial_client_img} elementor-animation-buzz`}
          src={props.client_img.type}
          alt="testimonal_logo"
        />
      <p className={`${classes.testimonial_text} elementor-animation-buzz`}>{props.content}</p>
      <div className={classes.testimonial_client}>
        
        <div className={classes.client_details}>
         
         
          <div className={`${classes.client_name} elementor-animation-buzz`}> {props.client_name}</div>
          <div className={classes.designation}> {props.designation}</div>
          
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

import React, { Fragment } from "react";
import Helmet from 'react-helmet';
import Card from "../components/UI/Card";
import experpic1 from "../assets/experpic1.webp"
import experpic2 from "../assets/experpic2.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandBackFist, faPersonDigging, faTimeline, faPeoplePulling } from '@fortawesome/free-solid-svg-icons';

const Mission = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
          Mission
        </title>
        <meta
          name="description"
          content="Mission"
        />
      </Helmet>


      <section className="containerFluid formcolor fadeInUp">
        <div className="container" style={{ paddingBottom: "120px" }}>
          <h2 className=" text-white mb-3 elementor-animation-buzz" style={{ font: "400 34px/40px 'Poppins'", paddingTop: "50px" }}>
            Mission
          </h2>
          <div>
            {/* <button className="btnMessage text-end" >ENQUIRE ABOUT WORKING WITH SSA</button> */}
          </div>

          <div className="row">

            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faHandBackFist} className="innovationIcon" />
              {/* <img src={Collaborative} style={{margin:"25px 0px",height:"100px"}} alt="img"></img> */}
              <h2 className="mb-3 text-white text-sm-center styh3">Empower</h2>
              <p className="para_font_600_20px text-sm-center styp">Opas empowers individuals to unlock their full potential by connecting talents with dynamic job opportunities in the UAE.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">


              <FontAwesomeIcon icon={faPersonDigging} className="innovationIcon" />
              {/* <img src={Global} style={{margin:"25px 0px",height:"100px"}} alt="img"></img> */}
              <h2 className="mb-3 text-white text-sm-center styh3">Construct</h2>
              <p className="para_font_600_20px text-sm-center styp">Opas is on a mission to construct not just buildings but legacies, setting new standards of excellence in construction services across the landscape of the UAE.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              {/* <img src={Preferred} style={{margin:"25px 0px",height:"100px"}} alt="img"></img> */}
              <FontAwesomeIcon icon={faTimeline} className="innovationIcon" />
              <h2 className="mb-3 text-white text-sm-center styh3">Harmonize</h2>
              <p className="para_font_600_20px text-sm-center styp">Opas harmonizes project elements seamlessly, offering comprehensive project management solutions that ensure efficient execution from inception to completion.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faPeoplePulling} className="innovationIcon" />
              {/* <img src={Varied } style={{margin:"25px 0px",height:"100px"}} alt="img"></img> */}
              <h2 className="mb-3 text-white text-sm-center styh3">Facilitate</h2>
              <p className="para_font_600_20px text-sm-center styp">Opas facilitates international trade effortlessly, providing end-to-end solutions for product trading, from sourcing quality goods to managing logistics with precision.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section_bottom_margin fadeInUp">
        <div className="d-grid-50-50 ">
          <div className="section1-2">
            <Card>
              <div className="px-sm-30 py-sm-2rem" style={{ padding: "120px" }}>
                <h2 className="mb-3 elementor-animation-buzz" style={{ font: "400 34px/40px'Poppins'" }}>Job/Naukri Mission</h2>
                <p className="expertisePara elementor-animation-buzz">At Opas, our mission in the Job/Naukri sector is to serve as the bridge connecting skilled professionals with rewarding career opportunities in the dynamic UAE job market. We are dedicated to facilitating meaningful connections, empowering individuals to achieve their career aspirations, and supporting businesses in acquiring top-tier talent.</p>


              </div>
            </Card>
          </div>
          <Card extraClasses="">
            <div>
              <img
                className="w-100 d-block elementor-animation-buzz"
                src={experpic1}
                alt="img"
              />
            </div>
          </Card>
        </div>
      </section>
      <section className="section_bottom_margin fadeInUp">
        <div className="d-grid-50-50">
          <Card extraClasses="">
            <div>
              <img
                className="w-100 d-block elementor-animation-buzz"
                src={experpic2}
                alt="img"
              />
            </div>
          </Card>
          <div className="section1-2">
            <Card>
              <div className=" px-sm-30 py-sm-2rem" style={{ padding: "120px" }}>
                <h2 className=" mb-3 elementor-animation-buzz" style={{ font: "400 34px/40px'Poppins'" }}>Construction Mission</h2>
                <p className="mb-2 para_font_400_16px expertisePara elementor-animation-buzz">In the realm of construction, Opas is on a mission to redefine excellence. Our commitment is to deliver high-quality construction services that not only meet but exceed the expectations of our clients. From residential developments to commercial projects, our mission is to contribute to the architectural landscape of the UAE with precision, innovation, and a commitment to sustainable construction practices.</p>
                {/* <ul className="expertisePara" style={{marginLeft:"20px"}}>
                      <li>Opas is dedicated to empowering individuals by bridging the gap between talent and opportunity.</li>
                      <li>Through our mission, we aim to be the catalyst for career growth, providing a platform where skills are recognized and nurtured.</li>
                      <li>Our commitment is to create a symbiotic relationship between professionals and employers, fostering a thriving job market in the UAE.</li>
                     
                     
                    </ul> */}

              </div>
            </Card>
          </div>
        </div>
      </section>

      <section className="section_bottom_margin fadeInUp" >
        <div className="d-grid-50-50 ">
          <div className="section1-2">
            <Card>
              <div className=" px-sm-30 py-sm-2rem" style={{ padding: "120px" }}>
                <h2 className="mb-3 elementor-animation-buzz" style={{ font: "400 34px/40px'Poppins'" }}>Project Management Mission</h2>
                <p className="expertisePara elementor-animation-buzz">At Opas, our project management mission is clear: to be the driving force behind the successful execution of projects. We aim to provide comprehensive project management solutions, ensuring seamless communication, adherence to timelines, and the efficient allocation of resources. Our mission is to empower businesses to achieve their project goals with confidence and precision.</p>


              </div>
            </Card>
          </div>
          <Card extraClasses="">
            <div>
              <img
                className="w-100 d-block elementor-animation-buzz"
                src={experpic1}
                alt="img"
              />
            </div>
          </Card>
        </div>
      </section>
      <section className="section_bottom_margin fadeInUp">
        <div className="d-grid-50-50 ">
          <Card extraClasses="">
            <div>
              <img
                className="w-100 d-block elementor-animation-buzz"
                src={experpic2}
                alt="img"
              />
            </div>
          </Card>
          <div className="section1-2">
            <Card>
              <div className="pb2-120px px-sm-30 py-sm-2rem">
                <h2 className=" mb-3 elementor-animation-buzz" style={{ font: "400 34px/40px'Poppins'" }}>Trading Mission</h2>
                <p className="mb-2 para_font_400_16px expertisePara elementor-animation-buzz">In the field of trading, [Your Company] embarks on a mission to facilitate international trade seamlessly. Our goal is to simplify the complexities of product trading, ensuring regulatory compliance, optimizing logistics, and fostering global connections. We are committed to being the trusted partner for businesses engaged in import and export activities, providing them with the tools and support needed to thrive in the global marketplace.</p>
                {/* <ul className="expertisePara" style={{marginLeft:"20px"}}>
                      <li>Opas is dedicated to empowering individuals by bridging the gap between talent and opportunity.</li>
                      <li>Through our mission, we aim to be the catalyst for career growth, providing a platform where skills are recognized and nurtured.</li>
                      <li>Our commitment is to create a symbiotic relationship between professionals and employers, fostering a thriving job market in the UAE.</li>
                     
                     
                    </ul> */}

              </div>
            </Card>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Mission;

import React, { Fragment } from "react";
import Helmet from 'react-helmet';
const TermsofService = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                Terms and Conditions
                </title>
                <meta
                    name="description"
                    content="Terms and Conditions"
                />
            </Helmet>
            <section className="bg-white pricing_banner_section policy fadeInUp">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1 policysH4 elementor-animation-buzz">
                    Terms and Conditions
                    </h1>
                    
                </div>
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px fadeInUp"  style={{ padding: "100px" }}>
                <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
                    <div className="directorshedaerp text-end">
                    <p style={{margin:"0px"}}><b className="policysH4">Address : </b>Business Center 1, M Floor,<br></br> The Meydan Hotel, Nad Al Sheba,<br></br> Dubai, U.A.E</p>
                    <p style={{margin:"0px"}}><b className="policysH4">Mobile Number: </b>+971502342494</p>
                    <p style={{margin:"0px"}}><b className="policysH4">Email: </b>Info@opas.ae</p>
                    </div>
                    <h4 className="policysH4 mb-3 mt-5">Introduction</h4>
                    <p className="directorshedaerp mb-3">These terms and conditions govern the relationship between OPAS BIZZ GENERAL TRADING L.L.C-FZ and its clients. The agreement is in compliance with the laws and regulations of the United Arab Emirates (U.A.E).</p>
                    <h4 className="policysH4 mb-3">Definitions</h4>
                    <p className="directorshedaerp mb-3">"Company" refers to OPAS BIZZ GENERAL TRADING L.L.C-FZ. "Client" refers to individuals or entities engaging in job providing and trading services with the Company.</p>
                    <h4 className="policysH4 mb-3">Scope of Services</h4>
                    <p className="directorshedaerp mb-3">The Company specializes in job providing services within the U.A.E. Trading of goods and services within the U.A.E. is also a core business activity.</p>
                    <h4 className="policysH4 mb-3">Client Obligations</h4>
                    <p className="directorshedaerp mb-3">Clients must provide accurate and complete information for job placement services. Trading clients must adhere to U.A.E. import/export regulations.</p>
                    <h4 className="policysH4 mb-3">Service Fees</h4>
                    <p className="directorshedaerp mb-3">Service fees for job placement are agreed upon before the commencement of services. Trading services may incur fees based on transaction values.</p>
                    <h4 className="policysH4 mb-3">Payment Terms</h4>
                    <p className="directorshedaerp mb-3">Invoices are payable within the agreed timeframe. Late payments may attract penalties.</p>
                    <h4 className="policysH4 mb-3">Confidentiality</h4>
                    <p className="directorshedaerp mb-3">Both parties agree to maintain the confidentiality of sensitive information exchanged during the course of business.</p>
                    <h4 className="policysH4 mb-3">Compliance with UAE Laws</h4>
                    <p className="directorshedaerp mb-3">Clients must comply with all U.A.E. laws and regulations related to job placement and trading activities.</p>
                    <h4 className="policysH4 mb-3">Employment Contracts</h4>
                    <p className="directorshedaerp mb-3">Job placement services include the drafting and execution of employment contracts in accordance with U.A.E. labor laws.</p>
                    <h4 className="policysH4 mb-3">Quality Assurance</h4>
                    <p className="directorshedaerp mb-3">The Company ensures the quality of goods and services traded, conforming to U.A.E. standards.</p>
                    <h4 className="policysH4 mb-3">Dispute Resolution</h4>
                    <p className="directorshedaerp mb-3">Disputes shall be resolved amicably through negotiation or mediation, in accordance with U.A.E. laws.</p>
                    <h4 className="policysH4 mb-3">Termination of Services</h4>
                    <p className="directorshedaerp mb-3">Either party may terminate services with written notice, adhering to the terms agreed upon.</p>
                    <h4 className="policysH4 mb-3">Force Majeure</h4>
                    <p className="directorshedaerp mb-3">Neither party shall be liable for failure to perform obligations due to unforeseen circumstances beyond their control, in compliance with U.A.E. law.</p>
                    <h4 className="policysH4 mb-3">Governing Law</h4>
                    <p className="directorshedaerp mb-3">The agreement is governed by and construed in accordance with the laws of the U.A.E.</p>
                    <h4 className="policysH4 mb-3">Indemnity</h4>
                    <p className="directorshedaerp mb-3">Clients indemnify the Company against any losses or damages resulting from their non-compliance with these terms and U.A.E. laws.</p>
                    <h4 className="policysH4 mb-3">Amendment of Terms</h4>
                    <p className="directorshedaerp mb-3">The Company reserves the right to amend these terms with prior notice to clients.</p>
                    <h4 className="policysH4 mb-3">Insurance</h4>
                    <p className="directorshedaerp mb-3">Clients engaging in trading activities are encouraged to secure appropriate insurance coverage as per U.A.E. regulations.</p>
                    <h4 className="policysH4 mb-3">Non-Solicitation</h4>
                    <p className="directorshedaerp mb-3">Clients agree not to solicit or hire employees placed by the Company for a specified period.</p>
                    <h4 className="policysH4 mb-3">Severability</h4>
                    <p className="directorshedaerp mb-3">If any provision of these terms is deemed invalid, the remaining provisions shall remain in full force and effect.</p>
                    <h4 className="policysH4 mb-3">Entire Agreement</h4>
                    <p className="directorshedaerp mb-3">These terms constitute the entire agreement between the parties, superseding all prior agreements and understandings.</p>
                    <h4 className="policysH4 mb-3">Representation</h4>
                    <p className="directorshedaerp mb-3">The parties represent that they have full authority to enter into this agreement.</p>
                    <h4 className="policysH4 mb-3">Electronic Communication</h4>
                    <p className="directorshedaerp mb-3">Electronic communication, including emails, shall be considered valid for all purposes under these terms.</p>
                    <h4 className="policysH4 mb-3">Anti-Bribery and Corruption</h4>
                    <p className="directorshedaerp mb-3">Clients shall adhere to U.A.E. laws and regulations related to anti-bribery and corruption.</p>
                    <h4 className="policysH4 mb-3">Data Protection</h4>
                    <p className="directorshedaerp mb-3">The Company ensures compliance with U.A.E. data protection laws in handling client information.</p>
                    <h4 className="policysH4 mb-3">Currency</h4>
                    <p className="directorshedaerp mb-3">All financial transactions shall be conducted in U.A.E. Dirhams, unless otherwise agreed upon.</p>
                    <h4 className="policysH4 mb-3">Non-Compete Clause</h4>
                    <p className="directorshedaerp mb-3">Clients agree not to engage in competing businesses that may adversely affect the Company.</p>
                    <h4 className="policysH4 mb-3">Social Responsibility</h4>
                    <p className="directorshedaerp mb-3">The Company and clients commit to social responsibility initiatives in line with U.A.E. norms.</p>
                    <h4 className="policysH4 mb-3">Non-Disclosure</h4>
                    <p className="directorshedaerp mb-3">Both parties agree not to disclose confidential information to third parties without prior written consent.</p>
                    <h4 className="policysH4 mb-3">Health and Safety</h4>
                    <p className="directorshedaerp mb-3">Clients engaging in trading of goods must adhere to U.A.E. health and safety regulations.</p>
                    <h4 className="policysH4 mb-3">Governing Language</h4>
                    <p className="directorshedaerp mb-3">The governing language of this agreement is English.</p>
                    <h4 className="policysH4 mb-3">Audit Rights</h4>
                    <p className="directorshedaerp mb-3">The Company reserves the right to audit client records for compliance purposes.</p>
                    <h4 className="policysH4 mb-3">Environmental Compliance</h4>
                    <p className="directorshedaerp mb-3">Clients must comply with U.A.E. environmental regulations in their trading activities.</p>
                    <h4 className="policysH4 mb-3">Successors and Assigns</h4>
                    <p className="directorshedaerp mb-3">These terms are binding upon the parties and their respective successors and assigns.</p>
                    <h4 className="policysH4 mb-3">Amendment of Trade License</h4>
                    <p className="directorshedaerp mb-3">Clients engaging in trading activities must notify the Company of any changes to their trade license.</p>
                    <h4 className="policysH4 mb-3">Intellectual Property</h4>
                    <p className="directorshedaerp mb-3">Any intellectual property developed during the course of business shall be owned by the party creating it.</p>
                    <h4 className="policysH4 mb-3">Anti-Money Laundering</h4>
                    <p className="directorshedaerp mb-3">Clients shall adhere to U.A.E. anti-money laundering laws and regulations.</p>
                    <h4 className="policysH4 mb-3">Code of Conduct</h4>
                    <p className="directorshedaerp mb-3">Clients must adhere to a code of conduct in line with U.A.E. business ethics.</p>
                    <h4 className="policysH4 mb-3">Anti-Terrorism Compliance</h4>
                    <p className="directorshedaerp mb-3">Clients shall not engage in any activities that support terrorism, in compliance with U.A.E. laws.</p>
                    <h4 className="policysH4 mb-3">Insurance Claims</h4>
                    <p className="directorshedaerp mb-3">Clients engaging in trading activities are responsible for processing and managing insurance claims.</p>
                    <h4 className="policysH4 mb-3">Record Keeping</h4>
                    <p className="directorshedaerp mb-3">Clients must maintain records in accordance with U.A.E. regulations.</p>
                    <h4 className="policysH4 mb-3">Exclusivity</h4>
                    <p className="directorshedaerp mb-3">The Company may provide exclusive job placement services to clients upon mutual agreement.</p>
                    <h4 className="policysH4 mb-3">Marketing and Advertising</h4>
                    <p className="directorshedaerp mb-3">Clients agree to collaborate on marketing and advertising efforts as deemed necessary by the Company.</p>
                    <h4 className="policysH4 mb-3">Anti-Discrimination</h4>
                    <p className="directorshedaerp mb-3">Clients must comply with U.A.E. anti-discrimination laws in their employment practices.</p>
                    <h4 className="policysH4 mb-3">Code of Ethics</h4>
                    <p className="directorshedaerp mb-3">Both parties agree to adhere to a code of ethics in conducting business.</p>
                    <h4 className="policysH4 mb-3">Trade Restrictions</h4>
                    <p className="directorshedaerp mb-3">Clients must comply with U.A.E. trade restrictions and sanctions.</p>
                    <h4 className="policysH4 mb-3">Subcontracting</h4>
                    <p className="directorshedaerp mb-3">The Company may subcontract services as necessary, ensuring compliance with U.A.E. regulations.</p>
                    <h4 className="policysH4 mb-3">Financial Reporting</h4>
                    <p className="directorshedaerp mb-3">Clients must provide accurate financial reports as requested by the Company for trading services.</p>
                    <h4 className="policysH4 mb-3">Employee Benefits</h4>
                    <p className="directorshedaerp mb-3">Clients must comply with U.A.E. laws regarding employee benefits and entitlements.</p>
                    <h4 className="policysH4 mb-3">Immigration Compliance</h4>
                    <p className="directorshedaerp mb-3">Clients engaging in job providing services must comply with U.A.E. immigration laws.</p>
                    <h4 className="policysH4 mb-3">Governing Authority</h4>
                    <p className="directorshedaerp mb-3">The Dubai Courts shall have exclusive jurisdiction over any disputes arising from this agreement, in compliance with U.A.E. laws.</p>
                    <p className="directorshedaerp mb-3">By engaging in business with OPAS BIZZ GENERAL TRADING L.L.C-FZ, the client agrees to abide by these terms and conditions, acknowledging their understanding of the legal and regulatory framework within the United Arab Emirates.</p>
                    
                    </section>
            </section>
        </Fragment>
    );
};

export default TermsofService;

import React from "react";
import { Fragment } from "react";
import Card from "../components/UI/Card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import Helmet from "react-helmet";
import had1 from "../assets/h1.jpg";
import Testimonials from "../assets/testimonial.png";
import Pledge from "../assets/whychooseus.jpg"
import Recruitments from "../assets/whychooseus2.jpg";
import Testimonial from "../components/testimonial/Testimonial";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faHandshake, faPeopleRoof, faCogs, faLocation, faSquareRss, faUserShield, faSnowflake, faCircleCheck } from '@fortawesome/free-solid-svg-icons';



const OurCompany = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Why Choose OPAS?</title>
        <meta
          name="description"
          content="Why Choose OPAS?"
        />
      </Helmet>
      <section className="inner_ban section_bottom_margin fadeInUp">
        {/* <Card extraClasses="px-200 py-100px powering_teams"> */}
        <img src={had1} style={{ width: "100%", height: "350px"}} alt="baner"></img>
        {/* </Card> */}
        <div className="banner_inner" >

          <h1 className="whyhading elementor-animation-buzz ">Why Choose OPAS?</h1>
        </div>
      </section>

      <section className="container-fluid formcolor fadeInUp">
        <div className="container" style={{ paddingBottom: "120px" }}>
          <h2 className="text-center text-white mb-3 h2header elementor-animation-buzz" style={{ font: "400 34px/40px 'Poppins'", paddingTop: "85px" }}>
            Choose Opas for a singular destination that seamlessly integrates expertise, local insight, and personalized solutions, embodying 'One Place, All Solutions' to elevate your success in the UAE job market, construction, project management, and product trading.
          </h2>
          <div className="row rowpadding">
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faLightbulb} className="innovationIcon" />
              <h3 className="mb-3 text-white text-sm-center styh3">Innovation</h3>
              <p className="para_font_600_20px text-sm-center styp">Opas stands at the forefront of industry innovation, pioneering solutions that redefine standards and propel businesses into the future.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faHandshake} className="innovationIcon" />
              
              <h2 className="mb-3 text-white text-sm-center styh3">Reliability</h2>
              <p className="para_font_600_20px text-sm-center styp">Count on Opas for unwavering reliability, where promises become assurances, and commitments are steadfastly upheld in every facet of our services.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz"  >
              <FontAwesomeIcon icon={faPeopleRoof} className="innovationIcon" />
              
              <h2 className="mb-3 text-white text-sm-center styh3">Expertise</h2>
              <p className="para_font_600_20px text-sm-center styp">We are experts across Construction and Civil Engineering with quality candidates on our global database.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faCogs} className="innovationIcon" />
              
              <h2 className="mb-3 text-white text-sm-center styh3">Efficiency</h2>
              <p className="para_font_600_20px text-sm-center styp">We have rigorous recruitment processes, in-depth interviews and vetting procedures ensure candidates are qualified, compliant and the right ‘fit’ for their company.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faLocation} className="innovationIcon" />
              
              <h2 className="mb-3 text-white text-sm-center styh3">Precision</h2>
              <p className="para_font_600_20px text-sm-center styp">Precision is the hallmark of Opas, where attention to detail and accuracy are embedded in every aspect, from construction projects to product trading.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faSquareRss} className="innovationIcon" />
              
              <h2 className="mb-3 text-white text-sm-center styh3">Simplicity</h2>
              <p className="para_font_600_20px text-sm-center styp">Opas brings simplicity to complexity, providing user-friendly solutions that make navigating the job market, construction, and trading endeavors effortlessly straightforward.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faSnowflake} className="innovationIcon" />
              
              <h2 className="mb-3 text-white text-sm-center styh3">Versatility</h2>
              <p className="para_font_600_20px text-sm-center styp">Opas embraces versatility, tailoring solutions to diverse needs and challenges, ensuring adaptability in an ever-evolving professional and business landscape.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-md-12 Approachcard rowpadingPhone elementor-animation-buzz">
              <FontAwesomeIcon icon={faUserShield} className="innovationIcon" />
              
              <h2 className="mb-3 text-white text-sm-center styh3">Trustworthiness</h2>
              <p className="para_font_600_20px text-sm-center styp">Trustworthiness is the foundation of Opas, where clients can rely on transparency, integrity, and a steadfast commitment to their success in every interaction.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section_bottom_margin fadeInUp">
        <div className="d-grid-50-50 ">
          <div className="section1-2">
            <Card extraClasses="pledgePhoneView">
              <div className="pOur-120px px-sm-30 py-sm-2rem">
                <h2 className="elementor-animation-buzz mb-3" style={{ font: "400 34px/40px 'Poppins'", marginLeft: "-35px" }}>Comprehensive Solutions</h2>
                <FontAwesomeIcon icon={faCircleCheck} className="navbar__div__link_dropdown_icon213" /><p className="mb-2 para_font_400_16px OurPledge elementor-animation-buzz">One-stop for job, construction, project management, and product trading solutions.</p>
                <FontAwesomeIcon icon={faCircleCheck} className="navbar__div__link_dropdown_icon213" /><p className="mb-2 para_font_400_16px OurPledge elementor-animation-buzz">Customized strategies to meet unique needs and ensure client success.</p>
                <FontAwesomeIcon icon={faCircleCheck} className="navbar__div__link_dropdown_icon213" /><p className="mb-2 para_font_400_16px OurPledge elementor-animation-buzz">Opas guides from initiation to completion, minimizing client complexities.</p>
                <FontAwesomeIcon icon={faCircleCheck} className="navbar__div__link_dropdown_icon213" /><p className="mb-2 para_font_400_16px OurPledge elementor-animation-buzz">Holistic approach connects services for maximum effectiveness and client benefit.</p>
                <FontAwesomeIcon icon={faCircleCheck} className="navbar__div__link_dropdown_icon213" /><p className="mb-2 para_font_400_16px OurPledge elementor-animation-buzz">Opas simplifies procedures, providing a smooth and efficient experience for clients.</p>
                <FontAwesomeIcon icon={faCircleCheck} className="navbar__div__link_dropdown_icon213" /><p className="mb-2 para_font_400_16px OurPledge elementor-animation-buzz">Opas leverages diverse knowledge to offer industry-relevant solutions across sectors.</p>
                <FontAwesomeIcon icon={faCircleCheck} className="navbar__div__link_dropdown_icon213" /><p className="mb-2 para_font_400_16px OurPledge elementor-animation-buzz">Opas' services evolve, ensuring resilience and sustainability in dynamic markets.</p>

              </div>
            </Card>
          </div>
          <Card extraClasses="">
            <div>
              <img
                className="w-100 d-block elementor-animation-buzz"
                src={Pledge}
                alt="baner"
              />
            </div>
          </Card>
        </div>
      </section>
      <section className="section_bottom_margin fadeInUp">
        <div className="d-grid-50-50 ">
          <Card extraClasses="">
            <div>
              <img
                className="w-100 d-block elementor-animation-buzz"
                src={Recruitments}
                alt="baner"
              />
            </div>
          </Card>
          <div className="section1-2 Local">
            <Card>
              <div className="pRecruitment-120px px-sm-30 py-sm-2rem">
                <h2 className="mb-3 elementor-animation-buzz" style={{ color: "#fff", font: "700 24px/33px 'Poppins'" }}>Local Insight, Global Success</h2>
                <p className="mb-2 para_font_400_16px recruitmentPara elementor-animation-buzz">With a deep understanding of the local market, Opas ensures that your endeavors in the UAE are not just successful but strategically aligned with the nuances of the region.</p>

                <h2 className=" mb-3 elementor-animation-buzz" style={{ color: "#fff", font: "700 24px/33px 'Poppins'" }}>Tailored to Your Success</h2>
                <p className="mb-2 para_font_400_16px recruitmentPara elementor-animation-buzz">Opas stands out for its commitment to delivering customized solutions, recognizing that every individual and business is unique, requiring personalized strategies for success.</p>
                <h2 className=" mb-3 elementor-animation-buzz" style={{ color: "#fff", font: "700 24px/33px 'Poppins'" }}>Excellence in Project Management</h2>
                <p className="mb-2 para_font_400_16px recruitmentPara elementor-animation-buzz">Opas is your trusted partner in project management, prioritizing communication, budget adherence, and timely delivery to ensure the seamless execution of your projects.</p>
              </div>
            </Card>
          </div>
        </div>
      </section>
      <section className="section_bottom_margin ml-30 mr-30 max-w-1600px fadeInUp" style={{ textAlign: "center" }}>
        <Card extraClasses="px-sm-30 py-sm-2rem p-100">

          <Card>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              <SwiperSlide>
                <Testimonial
                  client_img={<Testimonials />}
                  client_name="Job Placement"
                  // designation="ABC at somersault"
                  content="Opas turned job hunting into a breeze! Their portal connected me with the perfect opportunity, demonstrating their commitment to excellence in career services."
                />
              </SwiperSlide>
              <SwiperSlide>
                <Testimonial
                  client_img={<Testimonials />}
                  client_name="Construction Services"
                  // designation="ABC at somersault"
                  content="Opas exceeded our expectations in construction. Their expertise, attention to detail, and commitment to timelines made our project a true success."
                />
              </SwiperSlide>
              <SwiperSlide>
                <Testimonial
                  client_img={<Testimonials />}
                  client_name="Project Management"
                  // designation="ABC at somersault"
                  content="Opas brought efficiency and transparency to our projects. Their skilled project managers ensured seamless execution from start to finish. Highly recommended!"
                />
              </SwiperSlide>
              <SwiperSlide>
                <Testimonial
                  client_img={<Testimonials />}
                  client_name="Product Trading"
                  // designation="ABC at somersault"
                  content="Opas made international trade effortless. From sourcing to logistics, their comprehensive solutions simplified the complexities of product trading. A trustworthy partner."
                />
              </SwiperSlide>
            </Swiper>
            <div className="btnTestimonials elementor-animation-buzz"><Link to="/testimonials" className="testimonialsBtn">View All Testimonials</Link></div>
          </Card>
        </Card>
      </section>
    </Fragment>
  );
};

export default OurCompany;

import React, { Fragment, useState } from "react";
import classes from "./Navbar.module.css";
import { BsFillGridFill } from "react-icons/bs";
import { AiOutlineCaretDown} from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo  from "../../assets/logoops.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FaLinkedin} from "react-icons/fa";


const Navbar = () => {
  const [HamburgerIsOpen, SetHamburgerIsOpen] = useState(false);
  const [showProductDropDown, setShowProductDropDown] = useState(false);
  const [showResourcesDropDown, setShowResourcesDropDown] = useState(false);
  const [showComDropDown, setShowComDropDown] = useState(false);
  const hamburgerClickHandler = () => {
    if (!HamburgerIsOpen) {
      document
        .getElementById("navbar__mobile__div")
        .classList.add("slide_from_top");
      document
        .getElementById("navbar__mobile__div")
        .classList.remove("slide-from-bottom");
    } else {
      document
        .getElementById("navbar__mobile__div")
        .classList.add("slide-from-bottom");
      document
        .getElementById("navbar__mobile__div")
        .classList.remove("slide_from_top");
    }
    SetHamburgerIsOpen(!HamburgerIsOpen);
  };

  const toggleProductDropdown = (e) => {
    e.preventDefault();
    setShowProductDropDown(!showProductDropDown);
    setShowResourcesDropDown(false);
    setShowComDropDown(false);
  };



  const toggleResourceDropdown = (e) => {
    e.preventDefault();
    setShowResourcesDropDown(!showResourcesDropDown);
    setShowProductDropDown(false);
    setShowComDropDown(false);
  };
  const toggleComDropdown = (e) => {
    e.preventDefault();
    setShowComDropDown(!showComDropDown);
    setShowResourcesDropDown(false);
    setShowProductDropDown(false);
  };

  return (
    <div className={`${classes.navbar__div} `}>
      <div className={`${classes.navbar__div__logo} fadeInUp`}>
        <a href="/">
          <img src={logo}
            className="elementor-animation-buzz"
            alt="website_logo"
          
          />
        </a>
      </div>
      <div className={`${classes.navbar__div__links} fadeInUp`}>
        <ul className={classes.navbar__div__links_ul}>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onMouseOver={toggleProductDropdown}
            >
             Our Company
              
            </Link>
            {showProductDropDown ? (
              <Fragment>
                <div
                  className={classes.navbar_backdrop}
                  onMouseOver={() => {
                    setShowProductDropDown(!showProductDropDown);
                  }}
                ></div>
                <div className={(classes.productDropdown) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                  <div className="">
                    <div className="">
                      <div className="">
                        <div className={classes.product_text}>
                          <ul className="" style={{listStyle:"none"}}>
                            <li style={{marginTop:"20px"}}> 
                            <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_icon} />
                              <Link to="/" onClick={() => setShowProductDropDown(!showProductDropDown)}>For Clients</Link>
                            </li>
                            <li>
                            <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_icon} />
                              <Link to="/meet-the-team"  onClick={() => setShowProductDropDown(!showProductDropDown)}>Meet The Team</Link>
                            </li>
                            <li>
                            <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_icon} />
                              <Link to="/mission"  onClick={() => setShowProductDropDown(!showProductDropDown)}>Mission</Link>
                            </li>
                            <li>
                            <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_icon} />
                              <Link to="/vision"  onClick={() => setShowProductDropDown(!showProductDropDown)} >Vision</Link>
                            </li>
                            <li style={{marginBottom:"15px"}}>
                            <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_icon} />
                              <Link to="/testimonials"  onClick={() => setShowProductDropDown(!showProductDropDown)} >Testimonials</Link>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
            <Link to="https://jobs.opas.ae" onMouseOver={toggleComDropdown} className={classes.nav_barlink_dropdown}>
              Job Seekers
            </Link>
          </li>
          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
          <Link to="/ourproject">Our Project</Link>
          
          </li>
          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
            <Link to="/blogs">News & Resources</Link>
          </li>
          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
            <Link to="/opas-careers">Careers</Link>
          </li>
          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
            <Link to="https://trade.opas.ae">Discover Businesses in UAE</Link>
          </li>
          <li className={`${classes.navbar__div__link} elementor-animation-buzz`}>
            <Link to="/contact-us">Contact</Link>
          </li>

          <li className={`${classes.navbar__div__link} elementor-animation-buzz`} style={{marginLeft:"100px"}}>
            <Link to="https://in.linkedin.com/" onMouseOver={toggleResourceDropdown} className={classes.nav_barlink_dropdown}>
            <span><FaLinkedin style={{marginLeft:"30px",fontSize:"40px"}} /></span>
            </Link>
           
          </li>

        </ul>
      </div>

      {!HamburgerIsOpen ? (
        <BsFillGridFill
          onClick={hamburgerClickHandler}
          className={classes.navbar__hamburger}
        />
      ) : (
        <AiOutlineClose
          onClick={hamburgerClickHandler}
          className={classes.navbar__hamburger}
        />
      )}

      <div id="navbar__mobile__div" className={classes.navbar__mobile}>
        <ul>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onClick={toggleProductDropdown}
            >
              Our Company
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_iconMob}
              />
            </Link>
            {showProductDropDown ? (
              <Fragment>
                <div
                  onClick={() => {
                    setShowProductDropDown(!showProductDropDown);
                  }}
                ></div>
                <div>
                  <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                    <li> <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_iconMob1} />
                      <Link to="/" onClick={hamburgerClickHandler} >For Clients</Link>
                    </li>
                    <li> <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_iconMob1} />
                      <Link to="/meet-the-team"  onClick={hamburgerClickHandler}>Meet The Team</Link>
                    </li>
                    <li> <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_iconMob1} />
                      <Link to="/mission" onClick={hamburgerClickHandler}>Mission</Link>
                    </li>
                    <li> <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_iconMob1} />
                      <Link to="/vision" onClick={hamburgerClickHandler}>Vision</Link>
                    </li>
                    {/* <li> <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_iconMob1} />
                      <Link to="/opas-careers" onClick={hamburgerClickHandler}>OPAS Careers</Link>
                    </li> */}
                    <li> <FontAwesomeIcon icon={faCircleArrowRight} className={classes.navbar__div__link_dropdown_iconMob1} />
                      <Link to="/testimonials" onClick={hamburgerClickHandler}>Testimonials</Link>
                    </li>
                  </ul>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="https://jobs.opas.ae" onClick={hamburgerClickHandler} className={classes.nav_barlink_dropdown}>
            Job Seekers
            </Link>
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="/ourproject" onClick={hamburgerClickHandler}>Our Project</Link>
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="/blogs" onClick={hamburgerClickHandler}>News & Resources</Link>
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="/opas-careers">Careers</Link>
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="https://trade.opas.ae">Discover Businesses in UAE</Link>
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="/contact-us" onClick={hamburgerClickHandler}>Contact</Link>
          </li>
         
        </ul>
        <div className={classes.navbar__div__links_buttons}>
          
        </div>
      </div>
    </div>
  );
};

export default Navbar;
